import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-payroll',
  templateUrl: './show-payroll.component.html',
  styleUrls: ['./show-payroll.component.scss'],
})
export class ShowPayrollComponent implements OnInit {
  breadcrumbs: any = [];
  payrollData: any = [];
  paramId: string | null = '';

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de Nominas',
        path: '/payrolls/list',
        link: true,
      },
      {
        name: 'Nomina de colaborador',
        link: false,
      },
    ];

    this.getPayrollInfo();
  }

  getPayrollInfo(): void {
    this.api.getById('payroll', Number(this.paramId)).subscribe({
      next: (data) => {
        this.payrollData = data.data;
        console.log(this.payrollData.user);
      },
      error: (err) => {},
    });
  }
}
