<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Cambio de Sucursal</h3>
  </div>
  <hr />

  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
    <a [routerLink]="['/packages/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de paquetes
    </a>
  </div>
  <hr />

  <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
    <ngb-alert
      #selfClosingAlert
      type="danger"
      *ngIf="generalMessage != '' && isDisplayed"
    >
      {{ generalMessage }}
    </ngb-alert>
    <ngb-alert
      #selfClosingAlert
      type="success"
      *ngIf="generalMessage != '' && isSaved"
    >
      {{ generalMessage }}
    </ngb-alert>
  </div>

  <form [formGroup]="changeForm" (ngSubmit)="updateOffice()" novalidate>
    <div class="accordion" id="cities_accordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="cities-information">
          <button
            class="accordion-button color-header-collapse"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            disabled
          >
            <span class="color-text"> Cambio de Sucursal </span>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <input type="hidden" name="clientId" formControlName="clientId" />
            <div class="row">
              <div class="mb-3 form-floating col-md-6">
                <input
                  type="text"
                  name="clientName"
                  id="clientName"
                  formControlName="clientName"
                  class="form-control"
                  placeholder="Nombre del Cliente"
                  readonly
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.clientName.errors,
                    'is-valid': submitted && !f.clientName.errors
                  }"
                />
                <label for="clientName" class="ms-2">Nombre del Cliente</label>
              </div>
              <div class="mb-3 form-floating col-md-6">
                <input
                  type="text"
                  name="officeName"
                  id="officeName"
                  formControlName="officeName"
                  class="form-control"
                  placeholder="Sucursal Actual"
                  readonly
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.officeName.errors,
                    'is-valid': submitted && !f.officeName.errors
                  }"
                />
                <label for="officeName" class="ms-2">Sucursal Actual</label>
              </div>
            </div>

            <div class="row">
              <div class="mb-3 form-floating col-md-6">
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  formControlName="amount"
                  class="form-control"
                  placeholder="Precio de cambio de sucursal"
                  required
                  readonly
                  [ngClass]="{
                    'is-invalid': submitted && f.amount.errors,
                    'is-valid': submitted && !f.amount.errors
                  }"
                />
                <label for="amount" class="ms-2"
                  >Precio de cambio de sucursal</label
                >
              </div>
              <div class="col-md-6">
                <div class="mb-3 form-floating">
                  <select
                    class="form-select"
                    aria-label="Floating label select"
                    id="newOffice"
                    name="newOffice"
                    formControlName="newOffice"
                    [ngClass]="{
                      'is-invalid':
                        flagErrors && submitted && f.newOffice.errors,
                      'is-valid':
                        !flagErrors && submitted && !f.newOffice.errors
                    }"
                  >
                    <option selected value="">Selecciona una sucursal</option>
                    <option
                      *ngFor="let office of offices"
                      value="{{ office.id }}"
                    >
                      {{ office.name }}
                    </option>
                  </select>
                  <label for="newOffice" class="ms-2">
                    Selecciona una sucursal
                  </label>
                  <div>
                    <div
                      *ngIf="
                        flagErrors &&
                        f.newOffice?.invalid &&
                        (f.newOffice?.errors || f.newOffice?.touched)
                      "
                    >
                      <div
                        class="ms-2 text-danger"
                        *ngIf="f.newOffice?.errors.required"
                      >
                        La selección de la sucursal es requerido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="mb-3 form-floating">
                <select
                  class="form-select"
                  aria-label="Floating label select"
                  id="paymentMethodId"
                  name="paymentMethodId"
                  formControlName="paymentMethodId"
                  (change)="checkPaymentMethods($event)"
                  [ngClass]="{
                    'is-invalid':
                      flagErrors && submitted && f.paymentMethodId.errors,
                    'is-valid':
                      !flagErrors && submitted && !f.paymentMethodId.errors
                  }"
                >
                  <option selected value="">
                    Selecciona un metodo de pago
                  </option>
                  <option
                    *ngFor="let paymentMethod of payment_methods"
                    value="{{ paymentMethod.id }}"
                  >
                    {{ paymentMethod.payment_name }}
                  </option>
                </select>
                <label for="select_state" class="ms-2">
                  Selecciona un metodo de pago
                </label>
                <div>
                  <div
                    *ngIf="
                      flagErrors &&
                      f.payment_method?.invalid &&
                      (f.payment_method?.errors || f.paymentMethodId?.touched)
                    "
                  >
                    <div
                      class="ms-2 text-danger"
                      *ngIf="f.paymentMethodId?.errors.required"
                    >
                      Metodo de pago obligatorio, seleccione uno.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="form-floating mb-3 col-md-6"
                *ngIf="paymentsFilter.length > 0"
              >
                <select
                  name="paymentId"
                  id="paymentId"
                  class="form-control"
                  formControlName="paymentId"
                  placeholcer="Opcion de pago *"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.paymentId.errors,
                    'is-valid': submitted && !f.paymentId.errors
                  }"
                >
                  <option value="0" selected>
                    Seleccione la opci&oacute;n de pago
                  </option>
                  <option
                    value="{{ payment.id }}"
                    *ngFor="let payment of paymentsFilter"
                  >
                    {{ payment.name }}
                  </option>
                </select>
                <label for="paymentMethodId" class="ms-2"
                  >Opci&oacute;n de pago *</label
                >
              </div>
              <div
                class="form-floating mb-3 col-md-6"
                *ngIf="paymentsFilter.length > 0"
              >
                <select
                  name="terminal"
                  id="terminal"
                  class="form-control"
                  formControlName="terminal"
                  placeholcer="Selecciona una terminal *"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.terminal.errors,
                    'is-valid': submitted && !f.terminal.errors
                  }"
                >
                  <option value="0" selected>Seleccione una terminal</option>
                  <option value="Banorte">Banorte</option>
                  <option value="BBVA">BBVA</option>
                  <option value="Firstdata">Firstdata</option>
                  <option value="Netpay">Netpay</option>
                  <option value="Getnet">Getnet</option>
                </select>
                <label for="paymentMethodId" class="ms-2">
                  Selecciona una terminal *</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
      <button
        type="button"
        class="btn btn-danger"
        (click)="resetChangeOffice()"
      >
        <i class="bi bi-eraser"></i>
        Limpiar Informaci&oacute;n
      </button>
      <button type="submit" class="btn btn-success">
        <i class="bi bi-arrow-repeat"></i>
        Cambiar Sucursal
      </button>
    </div>
  </form>
</div>
