<div class="row">
  <div class="card bg-color-pink-ligth" style="width: 15rem">
    <div class="card-body">
      <h6 class="card-title">VENTA SEMANAL</h6>
      <p class="card-text fw-bold">
        {{ sellsPerOffice.totalPerOffice | currency : "MXN" }}
      </p>
    </div>
  </div>
  <div class="card bg-color-pink-ligth ms-2" style="width: 18rem">
    <div class="card-body">
      <h6 class="card-title">% DE VENTAS DEL EMPLEADO</h6>
      <div
        style="margin-top: 10px"
        class="fw-bold"
        *ngIf="sellsPerOffice.percentageEmployee == 0"
      >
        No hay cobranza
      </div>
      <div
        *ngIf="sellsPerOffice.percentageEmployee > 0"
        style="margin-top: 15px"
        class="progress"
        role="progressbar"
        aria-label="Animated striped example"
        aria-valuenow="sellsPerOffice.percentageEmployee"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <!-- style="width: 25%"-->
        <div
          class="progress-bar progress-bar-striped bg-success progress-bar-animated"
          [ngStyle]="{ 'width.%': sellsPerOffice.percentageEmployee }"
        >
          {{ sellsPerOffice.percentageEmployee }} %
        </div>
      </div>
      <!--p class="card-text fw-bold">
        {{ sellsPerOffice.totalPerEmployee | currency : "MXN" }}
      </p-->
    </div>
  </div>
</div>

<div class="row mb-2 mt-2 justify-content-end">
  <div class="col-md-4 mt-3">
    <button type="button" (click)="openBoxClose()" class="btn btn-primary">
      Corte de Caja
    </button>
    <!-- <button
      class="btn btn-primary ms-2"
      (click)="openChangeOffice()"
      *ngIf="keyRole == 'admin' || keyRole == 'rs'"
    >
      Cambio de sucursal
    </button> -->
  </div>
  <div class="col-md-4 mt-3">
    <form [formGroup]="formCalendarSearch">
      <div class="input-group">
        <input
          type="text"
          name="cal_search"
          class="form-control"
          id="cal_search"
          formControlName="cal_search"
          placeholder="Ir al dia ...."
          ngbDatepicker
          #d="ngbDatepicker"
          required
          readonly
          style="height: 2.7rem"
          (dateSelect)="goToDay()"
        />
        <button
          class="btn btn-outline-secondary bi bi-calendar3"
          (click)="d.toggle()"
          type="button"
        ></button>
        <button (click)="clearDay()" class="btn btn-danger">Limpiar</button>
      </div>
    </form>
  </div>
  <div class="col-md-4 mt-1">
    <form [formGroup]="formSearch">
      <select2
        style="margin-left: -10px; margin-right: 8px"
        [data]="perClientsOptions"
        formControlName="searchClient"
        displaySearchStatus="always"
        placeholder="Selecciona un cliente"
        resettable
        listPosition="below"
        styleMode="material"
        noResultMessage="Cliente no encontrado"
        (update)="showDataPerClient($event)"
        ><!-- calendarPerClientDate -->
      </select2>
      <button (click)="buttonClear()" class="btn btn-success">Limpiar</button>
    </form>
  </div>
</div>

<!-- test-->
<!-- <div class="col-md-3">
  <div
    class="card"
    mwlDroppable
    (drop)="externalDrop($event.dropData.event)"
    dragOverClass="drag-over"
  >
    <div class="card-body">
      <p *ngIf="externalEvents.length === 0"><em>No events added</em></p>
      <ul>
        <li
          *ngFor="let event of externalEvents"
          mwlDraggable
          [dropData]="{ event: event }"
          [touchStartLongPress]="{ delay: 300, delta: 30 }"
          dragActiveClass="drag-active"
        >
          <a href="javascript:;">
            {{ event.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div> -->
<!-- test-->

<mwl-utils-calendar-header
  [(view)]="view"
  [(viewDate)]="viewDate"
  (viewDateChange)="fetchEventsButton()"
  (viewChange)="fetchEventsButton()"
>
</mwl-utils-calendar-header>

<!-- cabinas -->
<div class="row">
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType1 }}">
    <div *ngIf="events1$ | async; let events">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName1 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag1 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 1, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 1)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events, 1)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 1, $event)"
        >
        </mwl-calendar-week-view>
        <!-- (eventClicked)="handleEvent('Clicked', $event.event, $event)" -->
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 1)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events, 1)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 1, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType2 }}">
    <div *ngIf="events2$ | async; let events2">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName2 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag2 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events2"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 2, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events2"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 2)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events2, 2)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 2, $event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events2"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 2)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events2, 2)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 2, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType3 }}">
    <div *ngIf="events3$ | async; let events3">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName3 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag3 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events3"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 3, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events3"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 3)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events3, 3)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 3, $event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events3"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 3)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events3, 3)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 3, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType4 }}">
    <div *ngIf="events4$ | async; let events4">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName4 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag4 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events4"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 4, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events4"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 4)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events4, 4)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 4, $event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events4"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 4)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events4, 4)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 4, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType5 }}">
    <div *ngIf="events5$ | async; let events5">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName5 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag5 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events5"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 5, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events5"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 5)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events5, 5)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 5, $event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events5"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 5)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events5, 5)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 5, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType6 }}">
    <div *ngIf="events6$ | async; let events6">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName6 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag6 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events6"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 6, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 6)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events6, 6)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 6, $event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events6"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 6)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events6, 6)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 6, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType7 }}">
    <div *ngIf="events7$ | async; let events7">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName7 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag7 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events7"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 7, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events7"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 7)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events7, 7)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 7, $event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events7"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 7)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events7, 7)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 7, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="col-md-{{ columnsSizes }} {{ cabinStyleType8 }}">
    <div *ngIf="events8$ | async; let events8">
      <div class="mb-2 card bg-color-pink-ligth">
        <div class="card-body">
          <h5 class="card-title text-center">{{ cabinName8 }}</h5>
        </div>
      </div>

      <div
        [ngSwitch]="view"
        [ngClass]="{ 'disabled-pointer-restday': cabinStyleFlag8 }"
      >
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events8"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (beforeViewRender)="beforeMonthViewRender($event)"
          (dayClicked)="dayClicked($event.day, $event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          (eventTimesChanged)="eventTimesChanged('Dragged', 8, $event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events8"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 8)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events8, 8)"
          (eventClicked)="handleEvent('Clicked', $event.event, $event)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 8, $event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events8"
          [hourSegments]="6"
          [refresh]="refresh"
          [dayStartHour]="hourStartAppt"
          [dayEndHour]="hourEndAppt"
          (beforeViewRender)="beforeWeekOrDayViewRender($event, 8)"
          (hourSegmentClicked)="hourSegmentClicked($event.date, events8, 8)"
          [snapDraggedEvents]="true"
          (eventTimesChanged)="eventTimesChanged('Dragged', 8, $event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
</div>
<!--ng-template #loading>
    <div class="text-center">
      <i class="fas fa-spin fa-spinner fa-5x"></i> <br />
      Cargando Citas
    </div>
  </ng-template-->
<!-- cabinas -->

<!-- display info client -->
<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Cita - {{ displayInfoAppoinment.client.name }} (
      <span
        class="fw-bold text-success"
        *ngIf="displayInfoAppoinment.assisted == 1"
      >
        Asistio /
      </span>
      <span
        class="fw-bold text-danger"
        *ngIf="displayInfoAppoinment.assisted == 0"
      >
        No asistio /
      </span>
      <span
        class="fw-bold text-success"
        *ngIf="displayInfoAppoinment.sessionClose == 1"
      >
        Sesi&oacute;n cerrada
      </span>
      <span
        class="fw-bold text-danger"
        *ngIf="displayInfoAppoinment.sessionClose == 0"
      >
        Sesi&oacute;n abierta
      </span>
      )
      <span *ngIf="displayInfoAppoinment.typeSession == 2">
        - Sesi&oacute;n de valoraci&oacute;n -
      </span>
      <button
        type="button"
        class="btn btn-outline-danger ms-2"
        (click)="deleteSession(displayInfoAppoinment.session_id)"
      >
        <span *ngIf="displayInfoAppoinment.typeSession == 1"
          >Eliminar Sesi&oacute;n</span
        >
        <span *ngIf="displayInfoAppoinment.typeSession == 2"
          >Eliminar Valoraci&oacute;n</span
        >
      </button>
      <button
        type="button"
        class="btn btn-outline-success ms-2"
        (click)="showFormEditSession($event)"
      >
        Editar Sesi&oacute;n
      </button>
    </h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div
      class="alert alert-success"
      role="alert"
      *ngIf="assistedFlag && submitAssisted"
    >
      {{ messageTextAssisted }}
    </div>

    <div *ngIf="editHourSession">
      <div class="card mb-1">
        <div class="card-body">
          <h5 class="card-title mb-1">Cambio de horario de sesi&oacute;n</h5>
          <div class="card-title">
            <form
              [formGroup]="updateFormSession"
              (ngSubmit)="updateDataSession()"
              novalidate
            >
              <input
                type="hidden"
                name="sess_id"
                formControlName="sess_id"
                id="sess_id"
              />
              <input
                type="hidden"
                name="cabinNumOrigin"
                formControlName="cabinNumOrigin"
                id="cabinNumOrigin"
              />
              <div class="row mb-3">
                <div class="col-md-3 offset-md-2">
                  <label for="startSession">Hora de inicio</label>
                  <ngb-timepicker
                    [(ngModel)]="startSession"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                    (ngModelChange)="changeHour(true)"
                  ></ngb-timepicker>
                </div>
                <div class="col-md-3 offset-md-2">
                  <label for="endSession">Hora de fin</label>
                  <ngb-timepicker
                    [(ngModel)]="endSession"
                    [ngModelOptions]="{ standalone: true }"
                    [minuteStep]="step"
                    [spinners]="true"
                  ></ngb-timepicker>
                  <div class="text-danger" *ngIf="submittedExist">
                    Por favor, selecciona una hora de termino de sesion.
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-floating mb-3 mt-3 col-md-12">
                  <select
                    name="cabinNum"
                    id="cabinNum"
                    class="form-select"
                    aria-label="floating label select"
                    formControlName="cabinNum"
                  >
                    <option value="" selected>Selecciona una cabina</option>
                    <option value="{{ cabin.id }}" *ngFor="let cabin of cabins">
                      {{ cabin.name }}
                    </option>
                  </select>
                  <label for="cabinNum" class="ms-2">
                    Selecciona una cabina
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="d-grip gap-2 d-md-flex justify-content-md-end">
                  <button type="submit" class="btn btn-outline-success">
                    Aceptar cambio de horario
                  </button>
                  <button
                    type="button"
                    (click)="showFormEditSession($event)"
                    class="btn btn-outline-danger"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!editHourSession">
      <div class="card mb-1" *ngIf="!acceptedSessions">
        <div class="card-body">
          <div class="row">
            <div class="ms-1 me-1 btn-group">
              <button
                type="button"
                class="btn btn-success"
                (click)="assistedUser(1, displayInfoAppoinment.session_id)"
              >
                <i class="bi bi-check-circle icon-color-button"></i>
                Si Asistio
              </button>
              <!-- assistedUser(0, displayInfoAppoinment[0].sessions.id) -->

              <button
                type="button"
                class="btn btn-danger"
                (click)="openNoAssisstForm(true, 0)"
              >
                <i class="bi bi-x-circle icon-color-button"></i>
                No Asistio
              </button>
              <button
                type="button"
                class="btn btn-warning"
                (click)="showRescheduleForm(true)"
              >
                <i class="bi bi-calendar3"></i>
                Reagendar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-1" *ngIf="noAssistFlag">
        <div class="card-body">
          <h5 class="card-title">
            Raz&oacute;n de <strong>No Asistencia</strong>
          </h5>
          <div class="card-title">
            <form
              [formGroup]="cancelForm"
              (ngSubmit)="noAssitedChecks()"
              novalidate
            >
              <input type="hidden" name="id" formControlName="id" />
              <input type="hidden" name="type" formControlName="type" />
              <div class="row">
                <div class="mb-3 input-group">
                  <span class="input-group-text"> Comentarios </span>
                  <textarea
                    name="comments"
                    id="comments"
                    class="form-control"
                    formControlName="comments"
                    aria-label="Comentarios"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="d-grip gap-2 d-md-flex justify-content-md-end">
                  <button type="submit" class="btn btn-outline-success">
                    Confirmar no asistencia
                  </button>
                  <button
                    type="button"
                    (click)="openNoAssisstForm(false, 1)"
                    class="btn btn-outline-danger"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Reschedule session -->
      <div
        class="alert alert-success mt-2"
        role="alert"
        *ngIf="submitReschedule && messageTextRescchedule != ''"
      >
        {{ messageTextRescchedule }}
      </div>
      <div class="card mb-1" *ngIf="reschedule">
        <div class="card-body">
          <h5 class="card-title">Reagendar Sesi&oacute;n</h5>
          <div class="card-title">
            <form
              [formGroup]="rescheduleForm"
              (ngSubmit)="rescheduleSession()"
              novalidate
            >
              <input type="hidden" name="sess_id" formControlName="sess_id" />
              <div class="row">
                <div class="mb-3 form-floating">
                  <div class="input-group">
                    <input
                      type="text"
                      name="new_day"
                      class="form-control"
                      id="new_day"
                      formControlName="new_day"
                      placeholder="Fecha de reagenda de sesion"
                      ngbDatepicker
                      #d="ngbDatepicker"
                      required
                      readonly
                      style="height: 3.5rem"
                      [ngClass]="{
                        'is-valid': submitReschedule && !reg.new_day.errors,
                        'is-invalid': submitReschedule && reg.new_day.errors
                      }"
                    />
                    <button
                      class="btn btn-outline-secondary bi bi-calendar3"
                      (click)="d.toggle()"
                      type="button"
                    ></button>
                    <div
                      *ngIf="
                        reg.new_day?.errors &&
                        reg.new_day?.invalid &&
                        submitReschedule
                      "
                    >
                      <div
                        class="text-danger"
                        *ngIf="reg.new_day.errors.required"
                      >
                        Por favor, seleccione una fecha para reagendar la
                        sesi&oacute;n.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-3 offset-md-2">
                  <label for="startSession">Hora de inicio</label>
                  <ngb-timepicker
                    [(ngModel)]="startSession"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
                <div class="col-md-3 offset-md-2">
                  <label for="endSession">Hora de fin</label>
                  <ngb-timepicker
                    [(ngModel)]="endSession"
                    [ngModelOptions]="{ standalone: true }"
                    [minuteStep]="step"
                    [spinners]="true"
                  ></ngb-timepicker>
                  <div class="text-danger" *ngIf="submittedExist">
                    Por favor, selecciona una hora de termino de sesion.
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="d-grip gap-2 d-md-flex justify-content-md-end">
                  <button type="submit" class="btn btn-outline-success">
                    Aceptar reagenda
                  </button>
                  <button
                    type="button"
                    (click)="showRescheduleForm(false)"
                    class="btn btn-outline-danger"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Reschedule session -->

      <div class="row mb-1" *ngIf="displayInfoAppoinment.typeSession == 1">
        <div class="btn-group">
          <!-- *ngIf="!displayAdvancePaymentsButton" -->
          <button
            class="btn btn-success"
            type="button"
            (click)="newPaymentsAddedSession(true)"
          >
            Realizar pagos adelantados
          </button>
          <button
            *ngIf="acceptedSessions && !areasWorkedDone"
            class="btn btn-info"
            type="button"
            (click)="newExtraResources(true)"
          >
            Pago de insumos extras
          </button>
        </div>
      </div>

      <!-- Pagos adelantados -->
      <div class="alert alert-success" role="alert" *ngIf="submittedApf">
        {{ messageAdvancePayment }}
      </div>
      <div *ngIf="newPaymentsAdded">
        <div class="card mt-1 mb-1" *ngIf="newPaymentsAdded">
          <div class="card-body">
            <h5 class="card-title mb-1 mt-1">Pagos adelantados</h5>
            <form
              [formGroup]="formAddedPayments"
              (ngSubmit)="confirmNewPayment()"
              novalidate
            >
              <input
                type="hidden"
                name="sessionId"
                formControlName="sessionId"
              />
              <input type="hidden" name="apptId" formControlName="apptId" />
              <div class="mb-3 form-floating">
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  formControlName="amount"
                  class="form-control"
                  placeholder="Cantidad a pagar"
                  required
                  [ngClass]="{
                    'is-invalid': submittedApf && apf.amount.errors,
                    'is-valid': submittedApf && !apf.amount.errors
                  }"
                />
                <label for="amount">Cantidad a pagar</label>
              </div>
              <div class="form-floating mb-3">
                <select
                  name="paymentMethodId"
                  id="paymentMethodId"
                  class="form-control"
                  formControlName="paymentMethodId"
                  placeholcer="Metodo de pago"
                  (change)="checkPaymentMethods($event, 'session')"
                  required
                  [ngClass]="{
                    'is-invalid': submittedApf && apf.paymentMethodId.errors,
                    'is-valid': submittedApf && !apf.paymentMethodId.errors
                  }"
                >
                  <option value="" selected>
                    Seleccione un metodo de pago
                  </option>
                  <option
                    value="{{ paymentMethod.id }}"
                    *ngFor="let paymentMethod of payment_methods"
                  >
                    {{ paymentMethod.payment_name }}
                  </option>
                </select>
                <label for="paymentMethodId"
                  >Seleccione un metodo de pago</label
                >
              </div>
              <div
                class="form-floating mb-3"
                *ngIf="paymentsFilter.length > 0 && paymentMethodFlag"
              >
                <select
                  name="paymentId"
                  id="paymentId"
                  class="form-control"
                  formControlName="paymentId"
                  placeholcer="Tipo de pago"
                  required
                  [ngClass]="{
                    'is-invalid': submittedApf && apf.paymentId.errors,
                    'is-valid': submittedApf && !apf.paymentId.errors
                  }"
                >
                  <option value="0" selected>Seleccione un tipo de pago</option>
                  <option
                    value="{{ payment.id }}"
                    *ngFor="let payment of paymentsFilter"
                  >
                    {{ payment.name }}
                  </option>
                </select>
                <label for="paymentMethodId">Tipo de pago</label>
              </div>
              <div
                class="form-floating mb-3"
                *ngIf="paymentsFilter.length > 0 && paymentMethodFlag"
              >
                <select
                  name="terminal"
                  id="terminal"
                  class="form-control"
                  formControlName="terminal"
                  placeholcer="Selecciona una terminal"
                  required
                  [ngClass]="{
                    'is-invalid': submittedApf && apf.terminal.errors,
                    'is-valid': submittedApf && !apf.terminal.errors
                  }"
                >
                  <option value="" selected>Seleccione una terminal</option>
                  <option value="Banorte">Banorte</option>
                  <option value="BBVA">BBVA</option>
                  <option value="Firstdata">Firstdata</option>
                  <option value="Netpay">Netpay</option>
                  <option value="Getnet">Getnet</option>
                </select>
                <label for="paymentMethodId">Selecciona una terminal</label>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  class="btn btn-danger"
                  type="submit"
                  (click)="newPaymentsAddedSession(false)"
                >
                  Cancelar
                </button>
                <button
                  class="btn btn-primary me-md-2"
                  type="submit"
                  [disabled]="assistedFlag"
                >
                  Realizar pago adelantado
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Pagos adelantados -->

      <!-- extra products -->
      <div *ngIf="newExtraPays">
        <div
          class="alert alert-success"
          role="alert"
          *ngIf="submitExtraPayments && extraPaymentsSessions"
        >
          {{ messageExtraPaymentSession }}
        </div>
        <div
          class="card mt-1 mb-1"
          *ngIf="acceptedSessions && !areasWorkedDone"
        >
          <div class="card-body">
            <h5 class="card-title mb-1 mt-1">Pago de insumos extras</h5>
            <form
              [formGroup]="extraPaymentsForm"
              (ngSubmit)="saveExtraPayments()"
            >
              <input
                type="hidden"
                name="sessionId"
                formControlName="sessionId"
              />
              <input
                type="hidden"
                name="remainingPayment"
                formControlName="remainingPayment"
              />
              <div class="mb-3">
                <input type="hidden" formControlName="amount" id="amount" />
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="extra"
                    name="extra"
                    value="1"
                    (change)="changeElement($event)"
                    id="extra1"
                  />
                  <label class="form-check-label ms-2" for="extra1">
                    Rastrillo
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="extra"
                    name="extra"
                    value="2"
                    (change)="changeElement($event)"
                    id="extra2"
                  />
                  <label class="form-check-label ms-2" for="extra2">
                    Perfilador
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="extra"
                    name="extra"
                    value="3"
                    (change)="changeElement($event)"
                    id="extra3"
                  />
                  <label class="form-check-label ms-2" for="extra3">
                    Ambos
                  </label>
                </div>
              </div>

              <div class="form-floating mb-3">
                <select
                  name="paymentMethodId"
                  id="paymentMethodId"
                  class="form-control"
                  formControlName="paymentMethodId"
                  placeholcer="Metodo de pago"
                  (change)="checkPaymentMethods($event, 'extra')"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.paymentMethodId.errors,
                    'is-valid': submitted && !f.paymentMethodId.errors
                  }"
                >
                  <option value="" selected>
                    Seleccione un metodo de pago
                  </option>
                  <option
                    value="{{ paymentMethod.id }}"
                    *ngFor="let paymentMethod of payment_methods"
                  >
                    {{ paymentMethod.payment_name }}
                  </option>
                </select>
                <label for="paymentMethodId"
                  >Seleccione un metodo de pago</label
                >
              </div>

              <div
                class="form-floating mb-3"
                *ngIf="paymentsFilter.length > 0 && extraShowFlag"
              >
                <select
                  name="paymentId"
                  id="paymentId"
                  class="form-control"
                  formControlName="paymentId"
                  placeholcer="Tipo de pago"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.paymentId.errors,
                    'is-valid': submitted && !f.paymentId.errors
                  }"
                >
                  <option value="0" selected>Seleccione un tipo de pago</option>
                  <option
                    value="{{ payment.id }}"
                    *ngFor="let payment of paymentsFilter"
                  >
                    {{ payment.name }}
                  </option>
                </select>
                <label for="paymentMethodId">Tipo de pago</label>
              </div>
              <div
                class="form-floating mb-3"
                *ngIf="paymentsFilter.length > 0 && extraShowFlag"
              >
                <select
                  name="terminal"
                  id="terminal"
                  class="form-control"
                  formControlName="terminal"
                  placeholcer="Selecciona una terminal"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.terminal.errors,
                    'is-valid': submitted && !f.terminal.errors
                  }"
                >
                  <option value="" selected>Seleccione una terminal</option>
                  <option value="Banorte">Banorte</option>
                  <option value="BBVA">BBVA</option>
                  <option value="Firstdata">Firstdata</option>
                  <option value="Netpay">Netpay</option>
                  <option value="Getnet">Getnet</option>
                </select>
                <label for="paymentMethodId">Selecciona una terminal</label>
              </div>

              <div class="row">
                <span>
                  <strong>
                    Saldo Total: {{ saldoTotalExtra | currency : "MXN" }}
                  </strong>
                </span>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  class="btn btn-danger"
                  type="submit"
                  (click)="newExtraResources(false)"
                >
                  Cancelar
                </button>
                <button
                  class="btn btn-primary me-md-2"
                  type="submit"
                  [disabled]="submitExtraPayments"
                >
                  Pagar insumos extras
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        *ngIf="!hasRemaining && displayInfoAppoinment.completeSession == true"
      >
        <div
          class="mt-2 alert alert-success"
          role="alert"
          *ngIf="messageTextPaymentDone != ''"
        >
          {{ messageTextPaymentDone }}
        </div>

        <div
          class="card mt-1 mb-1"
          *ngIf="
            acceptedSessions &&
            !paymentDone &&
            paymentsInfo.length != 0 &&
            displayInfoAppoinment.shouldPaid.length != 0 &&
            !flagDisabledButton
          "
        >
          <div class="card-body">
            <h5 class="card-title mb-1 mt-1">
              Cobro de sesi&oacute;n
              {{ displayInfoAppoinment.numberSession }}
            </h5>
            <form
              [formGroup]="paymentsForm"
              (ngSubmit)="savePaymentsSession()"
              novalidate
            >
              <input
                type="hidden"
                name="sessionId"
                formControlName="sessionId"
              />
              <input
                type="hidden"
                name="remainingPaymentId"
                formControlName="remainingPaymentId"
              />
              <input type="hidden" name="apptId" formControlName="apptId" />
              <input
                type="hidden"
                name="original_amount"
                formControlName="original_amount"
              />
              <div class="mb-2 form-floating">
                <input
                  type="text"
                  name="amount"
                  id="amount"
                  formControlName="amount"
                  class="form-control"
                  placeholder="Cantidad a pagar"
                  required
                  [readonly]="disabledAmountField"
                  (keyup)="changePriceLabel($event)"
                  [ngClass]="{
                    'is-invalid': submitted && f.amount.errors,
                    'is-valid': submitted && !f.amount.errors
                  }"
                />
                <label for="amount">Cantidad a pagar</label>
              </div>

              <div class="mb-2 ms-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="change_amount"
                  id="change_amout"
                  (change)="changeAmountPayed($event)"
                />
                <label class="form-check-label ms-2" for="change_amount">
                  Cambiar cantidad a pagar
                </label>
              </div>
              <!--div class="mb-3">
              <input
                type="hidden"
                formControlName="extra_amount"
                id="extra_amount"
              />
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="extra"
                  name="extra"
                  value="1"
                  (change)="changeElement($event)"
                  id="extra1"
                />
                <label class="form-check-label ms-2" for="extra1">
                  Rastrillo
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="extra"
                  name="extra"
                  value="2"
                  (change)="changeElement($event)"
                  id="extra2"
                />
                <label class="form-check-label ms-2" for="extra2">
                  Perfilador
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="extra"
                  name="extra"
                  value="3"
                  (change)="changeElement($event)"
                  id="extra3"
                />
                <label class="form-check-label ms-2" for="extra3">
                  Ambos
                </label>
              </div>
            </div-->
              <div class="form-floating mb-3">
                <select
                  name="paymentMethodId"
                  id="paymentMethodId"
                  class="form-control"
                  formControlName="paymentMethodId"
                  placeholcer="Metodo de pago"
                  (change)="checkPaymentMethods($event, 'session')"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.paymentMethodId.errors,
                    'is-valid': submitted && !f.paymentMethodId.errors
                  }"
                >
                  <option value="" selected>
                    Seleccione un metodo de pago
                  </option>
                  <option
                    value="{{ paymentMethod.id }}"
                    *ngFor="let paymentMethod of payment_methods"
                  >
                    {{ paymentMethod.payment_name }}
                  </option>
                </select>
                <label for="paymentMethodId"
                  >Seleccione un metodo de pago</label
                >
              </div>
              <div
                class="form-floating mb-3"
                *ngIf="paymentsFilter.length > 0 && paymentMethodFlag"
              >
                <select
                  name="paymentId"
                  id="paymentId"
                  class="form-control"
                  formControlName="paymentId"
                  placeholcer="Tipo de pago"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.paymentId.errors,
                    'is-valid': submitted && !f.paymentId.errors
                  }"
                >
                  <option value="0" selected>Seleccione un tipo de pago</option>
                  <option
                    value="{{ payment.id }}"
                    *ngFor="let payment of paymentsFilter"
                  >
                    {{ payment.name }}
                  </option>
                </select>
                <label for="paymentMethodId">Tipo de pago</label>
              </div>
              <div
                class="form-floating mb-3"
                *ngIf="paymentsFilter.length > 0 && paymentMethodFlag"
              >
                <select
                  name="terminal"
                  id="terminal"
                  class="form-control"
                  formControlName="terminal"
                  placeholcer="Selecciona una terminal"
                  required
                  [ngClass]="{
                    'is-invalid': submitted && f.terminal.errors,
                    'is-valid': submitted && !f.terminal.errors
                  }"
                >
                  <option value="" selected>Seleccione una terminal</option>
                  <option value="Banorte">Banorte</option>
                  <option value="BBVA">BBVA</option>
                  <option value="Firstdata">Firstdata</option>
                  <option value="Netpay">Netpay</option>
                  <option value="Getnet">Getnet</option>
                </select>
                <label for="paymentMethodId">Selecciona una terminal</label>
              </div>

              <div class="row">
                <span>
                  <strong
                    >Saldo Total: {{ saldoTotal | currency : "MXN" }}</strong
                  >
                </span>
              </div>
              <div
                class="d-grid gap-2 d-md-flex justify-content-md-end"
                *ngIf="!isPaidFlag"
              >
                <button
                  class="btn btn-primary me-md-2"
                  type="submit"
                  [disabled]="assistedFlag"
                >
                  Pagar sesi&oacute;n
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div *ngIf="!valorationFlag">
        <div
          *ngIf="messageAreasWorked != ''"
          class="mt-2 alert alert-success"
          role="alert"
        >
          {{ messageAreasWorked }}
        </div>
        <div
          class="card mb-1"
          *ngIf="
            (displayInfoAppoinment.shouldPaid.length == 0 &&
              acceptedSessions &&
              !areasWorkedDone) ||
            (acceptedSessions &&
              paymentDone &&
              !areasWorkedDone &&
              !typeSession &&
              !flagDisabledButton)
          "
        >
          <div class="card-body">
            <h5 class="card-title">Areas trabajadas en la sesi&oacute;n</h5>
            <form
              [formGroup]="missingForm"
              (ngSubmit)="missingAreasSession()"
              novalidate
            >
              <input
                type="hidden"
                formControlName="session_id"
                id="session_id"
              />
              <input type="hidden" formControlName="appnt_id" id="appnt_id" />
              <input type="hidden" formControlName="areas" id="areas" />
              <input
                type="hidden"
                formControlName="package_id"
                id="package_id"
              />
              <div class="row">
                <div class="col-md-4" *ngFor="let areas of areasInfoWorked">
                  <div class="form-check">
                    <!-- [disabled]="areas.remaining == 0 || disabledCheck" -->
                    <input
                      type="checkbox"
                      class="form-check-input"
                      (change)="workingAreas($event, areas.id, 2)"
                      id="{{ areas.id }}-{{ areas.name }}"
                      [disabled]="disabledCheck"
                    />
                    <label
                      for="{{ areas.id }}-{{ areas.name }}"
                      class="form-check-label"
                    >
                      {{ areas.name }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- *ngIf="totalAreasWorked != totalAreasMarked" -->
                <div class="form-check" style="margin-left: 12px !important">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [disabled]="disabledAll"
                    (change)="workingAreas($event, 'All', 1)"
                    id="all"
                  />
                  <label for="all" class="form-check-label">
                    Todas las areas se trabajaron
                  </label>
                </div>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  class="btn btn-primary me-md-2"
                  type="submit"
                  [disabled]="assistedFlag"
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div *ngIf="displayInfoAppoinment.sessionClose == false">
        <div
          class="alert alert-success"
          role="alert"
          *ngIf="submitComment && messageTextFinishSession != ''"
        >
          {{ messageTextFinishSession }}
        </div>
        <div
          class="card mb-1"
          *ngIf="
            (valorationFlag && acceptedSessions) ||
            (acceptedSessions &&
              paymentDone &&
              areasWorkedDone &&
              assistedFlag &&
              !finishSession) ||
            (typeSession && !finishSession) ||
            flagAreasWorked
          "
        >
          <div class="card-body">
            <h5 class="card-title">Finalizar Sesi&oacute;n</h5>
            <div class="card-text">
              <form
                [formGroup]="finalComment"
                (ngSubmit)="updateSession(displayInfoAppoinment.session_id)"
                novalidate
              >
                <div class="row">
                  <div class="form-floating mb-3 col-md-12">
                    <select
                      name="employee_id"
                      id="employee_id"
                      class="form-control"
                      formControlName="employee_id"
                      required
                      [ngClass]="{
                        'is-valid': submittedExist && !fe.employee_id.errors,
                        'is-invalid': submittedExist && fe.employee_id.errors
                      }"
                    >
                      <option selected value="">
                        Selecci&oacute;na tu enfermera
                      </option>
                      <option
                        value="{{ employee.id }}"
                        *ngFor="let employee of employees"
                      >
                        {{ employee.first_name }} {{ employee.last_name }} ({{
                          employee.email
                        }})
                      </option>
                    </select>
                    <label for="employeeId" class="ms-2">
                      Selecci&oacute;na tu enfermera
                    </label>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text"> Comentarios </span>
                  <textarea
                    name="final_comment"
                    id="final_comment"
                    class="form-control"
                    formControlName="final_comment"
                    aria-label="Comentarios"
                    [ngClass]="{
                      'is-invalid': submittedExist && fe.final_comment.errors,
                      'is-valid': submittedExist && !fe.final_comment.errors
                    }"
                  ></textarea>
                </div>

                <div class="row">
                  <div class="d-grip gap-2 d-md-flex justify-content-md-end">
                    <button type="submit" class="btn btn-outline-success">
                      <!-- submitComment -->
                      Finalizar Sesi&oacute;n
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="displayInfoAppoinment.assisted == 0"
      >
        <span *ngIf="displayInfoAppoinment.reason === null">
          Razon: No hay raz&oacute;n por la que no asistio a la sesi&oacute;n.
        </span>
        <span *ngIf="displayInfoAppoinment.reason !== null">
          Razon: {{ displayInfoAppoinment.reason }}
        </span>
      </div>

      <div class="card mb-1">
        <div class="card-body">
          <h5 class="card-title">
            Informacion del cliente
            <strong style="color: green" *ngIf="displayInfoAppoinment.shared">
              (Paquete compartido)
            </strong>
          </h5>
          <p class="card-text">
            Nombre del cliente:
            <strong>
              {{ displayInfoAppoinment.client.name }}
            </strong>
            <br />
            Correo electronico:
            <strong> {{ displayInfoAppoinment.client.email }} </strong><br />
            Telefono:
            <strong>
              {{ displayInfoAppoinment.client.phone }}
            </strong>
            <br />
            Sucursal:
            <strong> {{ displayInfoAppoinment.office.name }} </strong>
            <br />
            Fecha de compra:
            <strong>{{ displayInfoAppoinment.package.purchased }}</strong>
            <br />
            Comentarios de la sesi&oacute;n:
            <strong>{{ displayInfoAppoinment.comments }}</strong>
          </p>
        </div>
      </div>

      <div class="card mb-1">
        <div class="card-body">
          <h5 class="card-title">Informacion del colaborador</h5>
          <p class="card-text">
            Nombre del colaborador (Vendedor):
            <strong>
              {{ displayInfoAppoinment.employee.first_name }}
              {{ displayInfoAppoinment.employee.last_name }} </strong
            ><br />
            Correo electronico:
            <strong> {{ displayInfoAppoinment.employee.email }} </strong>
          </p>
        </div>
      </div>

      <div class="card mb-1" *ngIf="displayInfoAppoinment.typeSession == 1">
        <div class="card-body">
          <h5 class="card-title">Informacion del paquete</h5>
          <p class="card-text">
            Costo total del paquete:
            <strong>
              {{ displayInfoAppoinment.package.subtotal | currency : "MXN" }}
            </strong>
            <br />
            Anticipo:
            <strong>
              {{
                displayInfoAppoinment.package.advancePayment | currency : "MXN"
              }}
            </strong>
            <br />
            Restante a cubir:
            <strong>
              {{
                displayInfoAppoinment.package.remainingTotal | currency : "MXN"
              }}
            </strong>
            <br />
            Descuento:
            <strong> {{ displayInfoAppoinment.package.percentage }}% </strong>
            <br />
            Numero de sesiones:
            <strong>
              {{ displayInfoAppoinment.package.totalSessionNumber }}
            </strong>
            <br />
            Paquete Compartido:
            <strong>
              <span *ngIf="!displayInfoAppoinment.shared">No</span>
              <span *ngIf="displayInfoAppoinment.shared">Si</span>
            </strong>
            <br />
            Observaciones del paquete:
            <strong>{{ displayInfoAppoinment.package.observations }}</strong>
          </p>
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <th>Area</th>
                <th>Duracion</th>
                <th>Total de sesiones</th>
                <th>Sesiones tomadas</th>
                <th>Sesiones restantes</th>
                <th>Area terminada</th>
                <th *ngIf="displayInfoAppoinment.shared">Cliente</th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngFor="let area of displayInfoAppoinment.areas">
              <tr>
                <td>{{ area.name }}</td>
                <td>{{ area.duration }}</td>
                <td>{{ area.total }}</td>
                <td>{{ area.remaining }}</td>
                <td>{{ area.pending }}</td>
                <td>
                  {{ area.finished }}
                </td>
                <td *ngIf="displayInfoAppoinment.shared">
                  {{ area.user_shared }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card mb-1" *ngIf="displayInfoAppoinment.typeSession == 1">
        <div class="card-body">
          <h5 class="card-title">Informacion de pagos</h5>
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <th>Numero de sesion</th>
                <th>Pago requerido al cliente ($)</th>
                <th>Status del pago</th>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let payment of displayInfoAppoinment.payments;
                let idx = index
              "
            >
              <tr
                [ngStyle]="{
                  'background-color':
                    payment.payment > 0 &&
                    displayInfoAppoinment.numberSession == payment.sessionNumber
                      ? '#E0B0FF'
                      : ''
                }"
              >
                <td>{{ payment.session_number }}</td>
                <td>{{ payment.payment_original | currency : "MXN" }}</td>
                <td>{{ payment.is_paid ? "Pagado" : "No Pagado" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div class="card-body">
          <h5 class="card-title">Pagos realizados</h5>
          <div
            class="alert alert-danger mt-2"
            *ngIf="displayInfoAppoinment.payed.length == 0"
          >
            Por el momento, no hay pagos registrados para este paquete!
          </div>
          <table
            class="table table-striped"
            *ngIf="displayInfoAppoinment.payed.length != 0"
          >
            <thead class="table-dark">
              <tr>
                <th>Sesi&oacute;n</th>
                <th>Cantidad</th>
                <th>Metodo de pago</th>
                <th>Tipo de pago</th>
                <th>Terminal</th>
                <th>Comentarios</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody *ngFor="let payed of displayInfoAppoinment.payed">
              <tr>
                <td>{{ payed.session }}</td>
                <td>{{ payed.amount }}</td>
                <td>{{ payed.payment_method }}</td>
                <td>{{ payed.payment }}</td>
                <td>{{ payed.terminal }}</td>
                <td>{{ payed.extra }}</td>
                <td>{{ payed.filter }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="card mb-1"
        *ngIf="areasWorkedFlag && displayInfoAppoinment.typeSession == 1"
      >
        <div class="card-body">
          <h5 class="card-title">Areas trabajadas en la sesi&oacute;n</h5>
          <table class="table table-stiped">
            <thead class="table-dark">
              <tr>
                <th>ID</th>
                <th>Area</th>
                <th>Sesion</th>
                <th>Cita</th>
                <th>Trabajada</th>
              </tr>
            </thead>
            <tbody *ngFor="let areaWorked of displayInfoAppoinment.areasWorked">
              <tr>
                <td>{{ areaWorked.id }}</td>
                <td>{{ areaWorked.areas_name }}</td>
                <td>{{ areaWorked.session_id }}</td>
                <td>{{ areaWorked.appoinment_id }}</td>
                <td>{{ areaWorked.is_completed ? "Si" : "No" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card mb-1">
        <div class="card-body">
          <h5 class="card-title" *ngIf="displayInfoAppoinment.typeSession == 1">
            Comentarios de las sesiones
          </h5>
          <h5 class="card-title" *ngIf="displayInfoAppoinment.typeSession == 2">
            Comentario de la sesi&oacute;n de valoraci&oacute;n
          </h5>
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <th *ngIf="displayInfoAppoinment.typeSession == 1">
                  # de sesi&oacute;n
                </th>
                <th>Situaciones con las citas</th>
                <th>Fecha de situaciones con las citas</th>
                <th>Comentario de cabina</th>
                <th *ngIf="displayInfoAppoinment.typeSession == 1">
                  Fecha de comentario de cabina
                </th>
                <th *ngIf="displayInfoAppoinment.typeSession == 2">
                  Fecha de comentario de cabina de valoraci&oacute;n
                </th>
              </tr>
            </thead>
            <tbody *ngIf="displayInfoAppoinment.typeSession == 2">
              <tr>
                <td>{{ displayInfoAppoinment.comments }}</td>
                <td>{{ displayInfoAppoinment.created }}</td>
                <td>{{ displayInfoAppoinment.finalComments }}</td>
                <td>{{ displayInfoAppoinment.updated }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="displayInfoAppoinment.typeSession == 1">
              <tr
                *ngFor="let comment of displayInfoAppoinment.commentsSessions"
              >
                <td>
                  {{ comment.numberSession }}
                </td>
                <td>{{ comment.comments }}</td>
                <td>{{ comment.dateCreated }}</td>
                <td>{{ comment.finalComments }}</td>
                <td>{{ comment.dateUpdated }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      Cerrar
    </button>
  </div>
</ng-template>

<ng-template #createSession let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">{{ dynamicMessage }}</h5>
    <button type="button" (click)="close()" class="close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="ms-2 mt-1 mb-1" style="margin-left: 15px !important">
    <div class="form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="sessionType"
        id="sessionType2"
        (change)="showForms($event)"
        value="2"
      />
      <label class="form-check-label ms-1" for="sessionType2">
        Sesi&oacute;n de depilaci&oacute;n
      </label>
    </div>
    <div class="form-check-inline">
      <input
        type="radio"
        class="form-check-input"
        name="sessionType"
        id="sessionType7"
        (change)="showForms($event)"
      />
      <label for="sessionType7" class="form-check-label ms-1">
        Sesi&oacute;n de depilaci&oacute;n (Nueva)
      </label>
    </div>
    <div
      class="form-check-inline"
      *ngIf="loggedEmployee.office.id == 3 || loggedEmployee.office.id == 1"
    >
      <input
        class="form-check-input"
        type="radio"
        name="sessionType"
        id="sessionType2"
        (change)="showForms($event)"
        value="5"
      />
      <label class="form-check-label ms-1" for="sessionType2">
        Sesi&oacute;n de hidrafacial
      </label>
    </div>
    <div class="form-check-inline" *ngIf="loggedEmployee.office.id == 3">
      <input
        type="radio"
        class="form-check-input"
        name="sessionType"
        id="sessionType2"
        (change)="showForms($event)"
        value="6"
      />
      <label for="sessionType2" class="form-check-label ms-1">
        Sesi&oacute;n Radiofrecuencia
      </label>
    </div>
    <div class="form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="sessionType"
        id="sessionType1"
        (change)="showForms($event)"
        value="1"
      />
      <label class="form-check-label ms-1" for="sessionType1">
        Sesi&oacute;n de valoraci&oacute;n
      </label>
    </div>
    <div class="form-check-inline ms-1">
      <input
        type="radio"
        class="form-check-input"
        name="sessionType"
        id="sessionType2"
        value="4"
        (change)="showForms($event)"
      />
      <label for="sessionType3" class="form-check-label ms-1">
        Capacitaci&oacute;n
      </label>
    </div>
    <div
      class="form-check-inline"
      *ngIf="keyRole == 'admin' || keyRole == 'gte' || keyRole == 'eds'"
    >
      <input
        type="radio"
        class="form-check-input"
        name="sessionType"
        id="sessionType2"
        (change)="showForms($event)"
        value="3"
      />
      <label for="sessionType2" class="form-check-label ms-1">
        Agendar vacaciones
      </label>
    </div>
  </div>

  <div *ngIf="flagTypeSession == 1">
    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="disabledNewSession"
    >
      {{ messageNewSession }}
    </div>
    <div class="alert alert-danger mt-2 ms-2 me-2" *ngIf="flagValZero">
      {{ messageZero }}
    </div>
    <!-- Sesiones de valoracion -->
    <div class="modal-body">
      <form
        [formGroup]="appoinmentValoration"
        (ngSubmit)="valorationSession()"
        novalidate
      >
        <div class="row">
          <div class="form-floating mb-2">
            <select
              id="type_user"
              formControlName="type_user"
              class="form-select"
              aria-label="Floating label select"
              name="type_user"
              (change)="typeUserForm($event)"
            >
              <option value="" selected>Seleccione una opcion</option>
              <option value="1">Cliente registrado</option>
              <option value="2">Cliente no registrado</option>
            </select>
            <label for="type_user" class="ms-2"> Seleccione una opcion </label>
          </div>
        </div>
        <input
          type="hidden"
          name="office_id_val"
          formControlName="office_id_val"
        />
        <input
          type="hidden"
          name="appoinmentId_val"
          formControlName="appoinmentId_val"
        />
        <input type="hidden" name="cabinId_val" formControlName="cabinId_val" />
        <input
          type="hidden"
          name="cabinNum_val"
          formControlName="cabinNum_val"
        />
        <div class="row mt-2" *ngIf="flagTypeClient == 1">
          <input
            type="hidden"
            name="client_id_val"
            id="client_id_val"
            formControlName="client_id_val"
            required
          />
          <select2
            [data]="clientsFilter"
            displaySearchStatus="always"
            style="width: 100%"
            placeholder="Clientes"
            resettable
            listPosition="below"
            styleMode="material"
            noResultMessage="Cliente no encontrado"
            (update)="updateClientValoration($event)"
            [ngClass]="{
              'is-invalid': submit && fe2.client_id_val.errors,
              'is-valid': submit && !fe2.client_id_val.errors
            }"
          ></select2>
        </div>
        <div class="row mt-2" *ngIf="flagTypeClient == 2">
          <div class="mb-2 form-floating">
            <input
              type="text"
              name="name_no_client"
              id="name_no_client"
              formControlName="name_no_client"
              class="form-control"
              placeholder="Nombre del cliente"
              required
              [ngClass]="{
                'is-invalid': submitted && fe2.name_no_client.errors,
                'is-valid': submitted && !fe2.name_no_client.errors
              }"
            />
            <label for="first_name" class="ms-2">Nombre del cliente</label>
          </div>
        </div>
        <div class="row">
          <div class="form-floating mb-3 col-md-6">
            <input
              type="hidden"
              name="employee_id_val"
              id="employee_id_val"
              formControlName="employee_id_val"
            />
            <select
              name="employee_id_val_temp"
              id="employee_id_val_temp"
              class="form-control"
              formControlName="employee_id_val_temp"
              required
              [ngClass]="{
                'is-valid': submit && !fe2.employee_id_val.errors,
                'is-invalid': submit && fe2.employee_id_val.errors
              }"
            >
              <option selected value="">Selecciona un colaborador</option>
              <option
                value="{{ employee.id }}"
                *ngFor="let employee of employees"
              >
                {{ employee.first_name }} {{ employee.last_name }} ({{
                  employee.email
                }})
              </option>
            </select>
            <label for="employeeId_val" class="ms-2">
              Selecciona un colaborador
            </label>
          </div>
          <div class="col-md-6 mb-3 form-floating">
            <div class="input-group">
              <input
                type="text"
                name="dateSession_val"
                class="form-control"
                id="dateSession_val"
                formControlName="dateSession_val"
                placeholder="Fecha de inicio de sesion"
                ngbDatepicker
                #d="ngbDatepicker"
                required
                readonly
                style="height: 3.5rem"
                [ngClass]="{
                  'is-valid': submit && !fe2.dateSession_val.errors,
                  'is-invalid': submit && fe2.dateSession_val.errors
                }"
              />
              <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="d.toggle()"
                type="button"
              ></button>
              <div
                *ngIf="
                  fe2.dateSession_val?.errors &&
                  fe2.dateSession_val?.invalid &&
                  submittedExist
                "
              >
                <div
                  class="text-danger"
                  *ngIf="fe2.dateSession_val.errors.required"
                >
                  Por favor, seleccione una fecha de sesion.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3 offset-md-2">
            <label for="startSession">Hora de inicio</label>
            <ngb-timepicker
              [(ngModel)]="startSession"
              [ngModelOptions]="{ standalone: true }"
              [spinners]="true"
              (ngModelChange)="changeHour(false)"
            ></ngb-timepicker>
          </div>
          <div class="col-md-3 offset-md-2">
            <label for="endSession">Hora de fin</label>
            <div style="margin-top: 2.375rem">
              <ngb-timepicker
                [(ngModel)]="endSession"
                [ngModelOptions]="{ standalone: true }"
                [readonlyInputs]="true"
              ></ngb-timepicker>
            </div>

            <div
              class="text-danger"
              *ngIf="submittedExist && flagEndTimeValidation"
            >
              Por favor, selecciona una hora de termino de sesion.
            </div>
          </div>
        </div>

        <div class="input-group mb-3">
          <span class="input-group-text"> Comentarios </span>
          <textarea
            name="comments_val"
            id="comments_val"
            class="form-control"
            formControlName="comments_val"
            aria-label="Comentarios"
            [ngClass]="{
              'is-invalid': submit && fe2.comments_val.errors,
              'is-valid': submit && !fe2.comments_val.errors
            }"
          ></textarea>
        </div>

        <div class="row">
          <div class="d-grip gap-2 d-md-flex justify-content-md-end">
            <button
              [disabled]="disabledNewSession"
              type="submit"
              class="btn btn-outline-success"
              [disabled]="disableButton"
            >
              Agendar valoraci&oacute;n
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Sesiones de valoracion -->

  <div *ngIf="flagTypeSession == 2">
    <!-- Sesiones programadas -->
    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="disabledNewSession"
    >
      {{ messageNewSession }}
    </div>

    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="flagSessionActive"
    >
      La sesi&oacute;n abarca hora de descanso del colaborador. Por favor cambie
      la hora de la sesi&oacute;n.
    </div>
    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="showScheduleSession == 2"
    >
      No hay paquetes activos para el cliente seleccionado.
    </div>

    <div class="modal-body">
      <form
        [formGroup]="appoinmentExists"
        (ngSubmit)="existAppoinment()"
        novalidate
      >
        <input type="hidden" name="office_id" formControlName="office_id" />
        <input
          type="hidden"
          name="appoinmentId"
          formControlName="appoinmentId"
        />
        <input type="hidden" name="cabinId" formControlName="cabinId" />
        <input type="hidden" name="cabinNum" formControlName="cabinNum" />
        <div class="row">
          <div class="form-floating col-md-{{ clientColSize }}">
            <input
              type="hidden"
              name="client_id"
              id="client_id"
              formControlName="client_id"
              required
            />
            <select2
              [data]="clientsFilter"
              displaySearchStatus="always"
              style="width: 100%"
              placeholder="Clientes"
              resettable
              listPosition="below"
              styleMode="material"
              noResultMessage="Cliente no encontrado"
              (update)="updateClientPackage($event)"
              [ngClass]="{
                'is-invalid': submit && fe.client_id.errors,
                'is-valid': submit && !fe.client_id.errors
              }"
            ></select2>
          </div>
          <div class="form-floating mb-3 col-md-6" *ngIf="flagClientOpen">
            <select
              name="package_id"
              id="package_id"
              class="form-control"
              formControlName="package_id"
              required
              (change)="loadInformationExists($event)"
              [ngClass]="{
                'is-valid': submit && !fe.package_id.errors,
                'is-invalid': submit && fe.package_id.errors
              }"
            >
              <option selected value="">Selecciona un paquete</option>
              <option
                value="{{ package.id }}"
                *ngFor="let package of packageFilter"
              >
                {{ package.type }} {{ package.packageShared }} ({{
                  package.packageName
                }}
                - {{ package.sessionNumber }} sesiones)
              </option>
            </select>
            <label for="package_id" class="ms-2"> Paquetes </label>
          </div>
        </div>

        <div *ngIf="showScheduleSession == 1">
          <div class="row mb-2">
            <div class="form-check ms-2">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="type1"
                value="1"
                formControlName="type"
                [checked]="true"
                checked
                (change)="isEnabledCustomAreas($event)"
              />
              <label class="form-check-label" for="type1">
                Sesi&oacute;n completa
              </label>
            </div>
            <div class="form-check ms-2">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="type2"
                value="0"
                formControlName="type"
                (change)="isEnabledCustomAreas($event)"
              />
              <label class="form-check-label" for="type2">
                Sesi&oacute;n parcial
              </label>
            </div>
            <div *ngIf="fe.type?.errors && fe.type?.invalid && submittedExist">
              <div class="text-danger" *ngIf="fe.type.errors.required">
                Seleccione un tipo de sesion.
              </div>
            </div>
          </div>

          <div class="row mb-2" *ngIf="showTables">
            <div class="col-md-12">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="flagCustom"
                  formControlName="flagCustom"
                  (change)="showHideSimpleAreas($event)"
                />
                <label for="select_areas" class="form-check-label">
                  Selecciona areas especificas
                </label>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="flagSimpleAreas">
            <div
              class="col-md-4 mb-2"
              *ngFor="let areas of areasPackagesSelected"
            >
              <input
                type="checkbox"
                class="form-check-input"
                (change)="storeValueSelected($event, areas.sess_id)"
              />
              <label for="area">
                {{ areas.area }}
              </label>
            </div>
          </div>

          <div class="row">
            <div class="form-floating mb-3 col-md-6">
              <input
                type="hidden"
                name="employee_id"
                id="employee_id"
                formControlName="employee_id"
              />
              <select
                name="employee_id"
                id="employee_id"
                class="form-control"
                formControlName="employee_id_temp"
                required
                [ngClass]="{
                  'is-valid': submit && !fe.employee_id.errors,
                  'is-invalid': submit && fe.employee_id.errors
                }"
              >
                <option value="">Selecciona un colaborador</option>
                <option
                  value="{{ employee.id }}"
                  *ngFor="let employee of employees"
                >
                  {{ employee.first_name }} {{ employee.last_name }} ({{
                    employee.email
                  }})
                </option>
              </select>
              <label for="employeeId" class="ms-2">
                Selecciona un colaborador
              </label>
            </div>
            <div class="col-md-6 mb-3 form-floating">
              <input
                type="text"
                name="total_sessions"
                id="total_sessions"
                class="form-control"
                formControlName="total_sessions"
                placeholder="Total de sesiones restantes del paquete"
                required
                readonly
                [ngClass]="{
                  'is-valid': submit && !fe.total_sessions.errors,
                  'is-invalid': submit && fe.total_sessions.errors
                }"
              />
              <label for="total_sessions" class="ms-2">
                Total de sesiones restantes del paquete
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3 form-floating">
              <div class="input-group">
                <input
                  type="text"
                  name="dateSession"
                  class="form-control"
                  id="dateSession"
                  formControlName="dateSession"
                  placeholder="Fecha de inicio de sesion"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  required
                  readonly
                  style="height: 3.5rem"
                  [ngClass]="{
                    'is-valid': submit && !fe.dateSession.errors,
                    'is-invalid': submit && fe.dateSession.errors
                  }"
                />
                <button
                  class="btn btn-outline-secondary bi bi-calendar3"
                  (click)="d.toggle()"
                  type="button"
                ></button>
                <div
                  *ngIf="
                    fe.dateSession?.errors &&
                    fe.dateSession?.invalid &&
                    submittedExist
                  "
                >
                  <div
                    class="text-danger"
                    *ngIf="fe.dateSession.errors.required"
                  >
                    Por favor, seleccione una fecha de sesion.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3 form-floating">
              <input
                type="text"
                name="sessionNumber"
                id="sessionNumber"
                class="form-control"
                formControlName="sessionNumber"
                placeholder="Numero de sesion"
                required
                [ngClass]="{
                  'is-valid': submit && !fe.sessionNumber.errors,
                  'is-invalid': submit && fe.sessionNumber.errors
                }"
              />
              <label for="sessionNumber" class="ms-2"> Numero de sesion </label>
              <div
                *ngIf="
                  fe.sessionNumber?.errors &&
                  fe.sessionNumber?.invalid &&
                  submittedExist
                "
              >
                <div
                  class="text-danger"
                  *ngIf="fe.sessionNumber.errors.required"
                >
                  Por favor, selecciona una cita para establecer el numero de
                  sesion.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 offset-md-2">
              <label for="startSession">Hora de inicio</label>
              <ngb-timepicker
                [(ngModel)]="startSession"
                [ngModelOptions]="{ standalone: true }"
              ></ngb-timepicker>
            </div>
            <div class="col-md-3 offset-md-2">
              <label for="endSession">Hora de fin</label>
              <ngb-timepicker
                [(ngModel)]="endSession"
                [ngModelOptions]="{ standalone: true }"
                [minuteStep]="step"
              ></ngb-timepicker>
              <div
                class="text-danger"
                *ngIf="
                  submittedExist &&
                  (endSession.hour == '' || endSession.minute == '')
                "
              >
                Por favor, selecciona una hora de termino de sesion.
              </div>
            </div>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text"> Comentarios </span>
            <textarea
              name="comments"
              id="comments"
              class="form-control"
              formControlName="comments"
              aria-label="Comentarios"
              [ngClass]="{
                'is-invalid': submit && fe.comments.errors,
                'is-valid': submit && !fe.comments.errors
              }"
            ></textarea>
          </div>

          <div class="row">
            <div class="d-grip gap-2 d-md-flex justify-content-md-end">
              <button
                type="submit"
                class="btn btn-outline-success"
                [disabled]="!disabledScheduleButton"
              >
                <!-- disableButton || disabledScheduleButton-->
                Agendar sesion
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Sesiones programadas -->

  <div *ngIf="flagTypeSession == 5">
    <!-- Sesiones hidrafacial -->
    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="disabledNewSession"
    >
      {{ messageNewSession }}
    </div>

    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="flagSessionActive"
    >
      La sesi&oacute;n abarca hora de descanso del colaborador. Por favor cambie
      la hora de la sesi&oacute;n.
    </div>
    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="showScheduleSession == 2"
    >
      No hay paquetes activos para el cliente seleccionado.
    </div>

    <div class="modal-body">
      <form
        [formGroup]="appoinmentExists"
        (ngSubmit)="existAppoinment()"
        novalidate
      >
        <input type="hidden" name="office_id" formControlName="office_id" />
        <input
          type="hidden"
          name="appoinmentId"
          formControlName="appoinmentId"
        />
        <input type="hidden" name="cabinId" formControlName="cabinId" />
        <input type="hidden" name="cabinNum" formControlName="cabinNum" />
        <div class="row">
          <div class="form-floating col-md-{{ clientColSize }}">
            <input
              type="hidden"
              name="client_id"
              id="client_id"
              formControlName="client_id"
              required
            />
            <select2
              [data]="clientsFilter"
              displaySearchStatus="always"
              style="width: 100%"
              placeholder="Clientes"
              resettable
              listPosition="below"
              styleMode="material"
              noResultMessage="Cliente no encontrado"
              (update)="updateClientPackage($event)"
              [ngClass]="{
                'is-invalid': submit && fe.client_id.errors,
                'is-valid': submit && !fe.client_id.errors
              }"
            ></select2>
          </div>
          <div class="form-floating mb-3 col-md-6" *ngIf="flagClientOpen">
            <select
              name="package_id"
              id="package_id"
              class="form-control"
              formControlName="package_id"
              required
              (change)="loadInformationExists($event)"
              [ngClass]="{
                'is-valid': submit && !fe.package_id.errors,
                'is-invalid': submit && fe.package_id.errors
              }"
            >
              <option selected value="">Selecciona un paquete</option>
              <option
                value="{{ package.id }}"
                *ngFor="let package of packageFilter"
              >
                {{ package.type }} {{ package.packageShared }} ({{
                  package.packageName
                }}
                - {{ package.sessionNumber }} sesiones)
              </option>
            </select>
            <label for="package_id" class="ms-2"> Paquetes </label>
          </div>
        </div>

        <div *ngIf="showScheduleSession == 1">
          <div class="row mb-2">
            <div class="form-check ms-2">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="type1"
                value="1"
                formControlName="type"
                [checked]="true"
                checked
                (change)="isEnabledCustomAreas($event)"
              />
              <label class="form-check-label" for="type1">
                Sesi&oacute;n completa
              </label>
            </div>
            <div class="form-check ms-2">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="type2"
                value="0"
                formControlName="type"
                (change)="isEnabledCustomAreas($event)"
              />
              <label class="form-check-label" for="type2">
                Sesi&oacute;n parcial
              </label>
            </div>
            <div *ngIf="fe.type?.errors && fe.type?.invalid && submittedExist">
              <div class="text-danger" *ngIf="fe.type.errors.required">
                Seleccione un tipo de sesion.
              </div>
            </div>
          </div>

          <div class="row mb-2" *ngIf="showTables">
            <div class="col-md-12">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="flagCustom"
                  formControlName="flagCustom"
                  (change)="showHideSimpleAreas($event)"
                />
                <label for="select_areas" class="form-check-label">
                  Selecciona areas especificas
                </label>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="flagSimpleAreas">
            <div
              class="col-md-4 mb-2"
              *ngFor="let areas of areasPackagesSelected"
            >
              <input
                type="checkbox"
                class="form-check-input"
                (change)="storeValueSelected($event, areas.sess_id)"
              />
              <label for="area">
                {{ areas.area }}
              </label>
            </div>
          </div>

          <div class="row">
            <div class="form-floating mb-3 col-md-6">
              <input
                type="hidden"
                name="employee_id"
                id="employee_id"
                formControlName="employee_id"
              />
              <select
                name="employee_id"
                id="employee_id"
                class="form-control"
                formControlName="employee_id_temp"
                required
                [ngClass]="{
                  'is-valid': submit && !fe.employee_id.errors,
                  'is-invalid': submit && fe.employee_id.errors
                }"
              >
                <option value="">Selecciona un colaborador</option>
                <option
                  value="{{ employee.id }}"
                  *ngFor="let employee of employees"
                >
                  {{ employee.first_name }} {{ employee.last_name }} ({{
                    employee.email
                  }})
                </option>
              </select>
              <label for="employeeId" class="ms-2">
                Selecciona un colaborador
              </label>
            </div>
            <div class="col-md-6 mb-3 form-floating">
              <input
                type="text"
                name="total_sessions"
                id="total_sessions"
                class="form-control"
                formControlName="total_sessions"
                placeholder="Total de sesiones restantes del paquete"
                required
                readonly
                [ngClass]="{
                  'is-valid': submit && !fe.total_sessions.errors,
                  'is-invalid': submit && fe.total_sessions.errors
                }"
              />
              <label for="total_sessions" class="ms-2">
                Total de sesiones restantes del paquete
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3 form-floating">
              <div class="input-group">
                <input
                  type="text"
                  name="dateSession"
                  class="form-control"
                  id="dateSession"
                  formControlName="dateSession"
                  placeholder="Fecha de inicio de sesion"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  required
                  readonly
                  style="height: 3.5rem"
                  [ngClass]="{
                    'is-valid': submit && !fe.dateSession.errors,
                    'is-invalid': submit && fe.dateSession.errors
                  }"
                />
                <button
                  class="btn btn-outline-secondary bi bi-calendar3"
                  (click)="d.toggle()"
                  type="button"
                ></button>
                <div
                  *ngIf="
                    fe.dateSession?.errors &&
                    fe.dateSession?.invalid &&
                    submittedExist
                  "
                >
                  <div
                    class="text-danger"
                    *ngIf="fe.dateSession.errors.required"
                  >
                    Por favor, seleccione una fecha de sesion.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3 form-floating">
              <input
                type="text"
                name="sessionNumber"
                id="sessionNumber"
                class="form-control"
                formControlName="sessionNumber"
                placeholder="Numero de sesion"
                required
                [ngClass]="{
                  'is-valid': submit && !fe.sessionNumber.errors,
                  'is-invalid': submit && fe.sessionNumber.errors
                }"
              />
              <label for="sessionNumber" class="ms-2"> Numero de sesion </label>
              <div
                *ngIf="
                  fe.sessionNumber?.errors &&
                  fe.sessionNumber?.invalid &&
                  submittedExist
                "
              >
                <div
                  class="text-danger"
                  *ngIf="fe.sessionNumber.errors.required"
                >
                  Por favor, selecciona una cita para establecer el numero de
                  sesion.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 offset-md-2">
              <label for="startSession">Hora de inicio</label>
              <ngb-timepicker
                [(ngModel)]="startSession"
                [ngModelOptions]="{ standalone: true }"
              ></ngb-timepicker>
            </div>
            <div class="col-md-3 offset-md-2">
              <label for="endSession">Hora de fin</label>
              <ngb-timepicker
                [(ngModel)]="endSession"
                [ngModelOptions]="{ standalone: true }"
                [minuteStep]="step"
              ></ngb-timepicker>
              <div
                class="text-danger"
                *ngIf="
                  submittedExist &&
                  (endSession.hour == '' || endSession.minute == '')
                "
              >
                Por favor, selecciona una hora de termino de sesion.
              </div>
            </div>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text"> Comentarios </span>
            <textarea
              name="comments"
              id="comments"
              class="form-control"
              formControlName="comments"
              aria-label="Comentarios"
              [ngClass]="{
                'is-invalid': submit && fe.comments.errors,
                'is-valid': submit && !fe.comments.errors
              }"
            ></textarea>
          </div>

          <div class="row">
            <div class="d-grip gap-2 d-md-flex justify-content-md-end">
              <button
                type="submit"
                class="btn btn-outline-success"
                [disabled]="!disabledScheduleButton"
              >
                Agendar sesion hidrafacial
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="flagTypeSession == 6">
    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="disabledNewSession"
    >
      {{ messageNewSession }}
    </div>

    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="flagSessionActive"
    >
      La sesi&oacute;n abarca hora de descanso del colaborador. Por favor cambie
      la hora de la sesi&oacute;n.
    </div>
    <div
      class="alert alert-danger mt-2 ms-2 me-2"
      role="alert"
      *ngIf="showScheduleSession == 2"
    >
      No hay paquetes activos para el cliente seleccionado.
    </div>

    <div class="modal-body">
      <form
        [formGroup]="appoinmentExists"
        (ngSubmit)="existAppoinment()"
        novalidate
      >
        <input type="hidden" name="office_id" formControlName="office_id" />
        <input
          type="hidden"
          name="appoinmentId"
          formControlName="appoinmentId"
        />
        <input type="hidden" name="cabinId" formControlName="cabinId" />
        <input type="hidden" name="cabinNum" formControlName="cabinNum" />
        <div class="row">
          <div class="form-floating col-md-{{ clientColSize }}">
            <input
              type="hidden"
              name="client_id"
              id="client_id"
              formControlName="client_id"
              required
            />
            <select2
              [data]="clientsFilter"
              displaySearchStatus="always"
              style="width: 100%"
              placeholder="Clientes"
              resettable
              listPosition="below"
              styleMode="material"
              noResultMessage="Cliente no encontrado"
              (update)="updateClientPackage($event)"
              [ngClass]="{
                'is-invalid': submit && fe.client_id.errors,
                'is-valid': submit && !fe.client_id.errors
              }"
            ></select2>
          </div>
          <div class="form-floating mb-3 col-md-6" *ngIf="flagClientOpen">
            <select
              name="package_id"
              id="package_id"
              class="form-control"
              formControlName="package_id"
              required
              (change)="loadInformationExists($event)"
              [ngClass]="{
                'is-valid': submit && !fe.package_id.errors,
                'is-invalid': submit && fe.package_id.errors
              }"
            >
              <option selected value="">Selecciona un paquete</option>
              <option
                value="{{ package.id }}"
                *ngFor="let package of packageFilter"
              >
                {{ package.type }} {{ package.packageShared }} ({{
                  package.packageName
                }}
                - {{ package.sessionNumber }} sesiones)
              </option>
            </select>
            <label for="package_id" class="ms-2"> Paquetes </label>
          </div>
        </div>

        <div *ngIf="showScheduleSession == 1">
          <div class="row mb-2">
            <div class="form-check ms-2">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="type1"
                value="1"
                formControlName="type"
                [checked]="true"
                checked
                (change)="isEnabledCustomAreas($event)"
              />
              <label class="form-check-label" for="type1">
                Sesi&oacute;n completa
              </label>
            </div>
            <div class="form-check ms-2">
              <input
                class="form-check-input"
                type="radio"
                name="type"
                id="type2"
                value="0"
                formControlName="type"
                (change)="isEnabledCustomAreas($event)"
              />
              <label class="form-check-label" for="type2">
                Sesi&oacute;n parcial
              </label>
            </div>
            <div *ngIf="fe.type?.errors && fe.type?.invalid && submittedExist">
              <div class="text-danger" *ngIf="fe.type.errors.required">
                Seleccione un tipo de sesion.
              </div>
            </div>
          </div>

          <div class="row mb-2" *ngIf="showTables">
            <div class="col-md-12">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="flagCustom"
                  formControlName="flagCustom"
                  (change)="showHideSimpleAreas($event)"
                />
                <label for="select_areas" class="form-check-label">
                  Selecciona areas especificas
                </label>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="flagSimpleAreas">
            <div
              class="col-md-4 mb-2"
              *ngFor="let areas of areasPackagesSelected"
            >
              <input
                type="checkbox"
                class="form-check-input"
                (change)="storeValueSelected($event, areas.sess_id)"
              />
              <label for="area">
                {{ areas.area }}
              </label>
            </div>
          </div>

          <div class="row">
            <div class="form-floating mb-3 col-md-6">
              <input
                type="hidden"
                name="employee_id"
                id="employee_id"
                formControlName="employee_id"
              />
              <select
                name="employee_id"
                id="employee_id"
                class="form-control"
                formControlName="employee_id_temp"
                required
                [ngClass]="{
                  'is-valid': submit && !fe.employee_id.errors,
                  'is-invalid': submit && fe.employee_id.errors
                }"
              >
                <option value="">Selecciona un colaborador</option>
                <option
                  value="{{ employee.id }}"
                  *ngFor="let employee of employees"
                >
                  {{ employee.first_name }} {{ employee.last_name }} ({{
                    employee.email
                  }})
                </option>
              </select>
              <label for="employeeId" class="ms-2">
                Selecciona un colaborador
              </label>
            </div>
            <div class="col-md-6 mb-3 form-floating">
              <input
                type="text"
                name="total_sessions"
                id="total_sessions"
                class="form-control"
                formControlName="total_sessions"
                placeholder="Total de sesiones restantes del paquete"
                required
                readonly
                [ngClass]="{
                  'is-valid': submit && !fe.total_sessions.errors,
                  'is-invalid': submit && fe.total_sessions.errors
                }"
              />
              <label for="total_sessions" class="ms-2">
                Total de sesiones restantes del paquete
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3 form-floating">
              <div class="input-group">
                <input
                  type="text"
                  name="dateSession"
                  class="form-control"
                  id="dateSession"
                  formControlName="dateSession"
                  placeholder="Fecha de inicio de sesion"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  required
                  readonly
                  style="height: 3.5rem"
                  [ngClass]="{
                    'is-valid': submit && !fe.dateSession.errors,
                    'is-invalid': submit && fe.dateSession.errors
                  }"
                />
                <button
                  class="btn btn-outline-secondary bi bi-calendar3"
                  (click)="d.toggle()"
                  type="button"
                ></button>
                <div
                  *ngIf="
                    fe.dateSession?.errors &&
                    fe.dateSession?.invalid &&
                    submittedExist
                  "
                >
                  <div
                    class="text-danger"
                    *ngIf="fe.dateSession.errors.required"
                  >
                    Por favor, seleccione una fecha de sesion.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-3 form-floating">
              <input
                type="text"
                name="sessionNumber"
                id="sessionNumber"
                class="form-control"
                formControlName="sessionNumber"
                placeholder="Numero de sesion"
                required
                [ngClass]="{
                  'is-valid': submit && !fe.sessionNumber.errors,
                  'is-invalid': submit && fe.sessionNumber.errors
                }"
              />
              <label for="sessionNumber" class="ms-2"> Numero de sesion </label>
              <div
                *ngIf="
                  fe.sessionNumber?.errors &&
                  fe.sessionNumber?.invalid &&
                  submittedExist
                "
              >
                <div
                  class="text-danger"
                  *ngIf="fe.sessionNumber.errors.required"
                >
                  Por favor, selecciona una cita para establecer el numero de
                  sesion.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 offset-md-2">
              <label for="startSession">Hora de inicio</label>
              <ngb-timepicker
                [(ngModel)]="startSession"
                [ngModelOptions]="{ standalone: true }"
              ></ngb-timepicker>
            </div>
            <div class="col-md-3 offset-md-2">
              <label for="endSession">Hora de fin</label>
              <ngb-timepicker
                [(ngModel)]="endSession"
                [ngModelOptions]="{ standalone: true }"
                [minuteStep]="step"
              ></ngb-timepicker>
              <div
                class="text-danger"
                *ngIf="
                  submittedExist &&
                  (endSession.hour == '' || endSession.minute == '')
                "
              >
                Por favor, selecciona una hora de termino de sesion.
              </div>
            </div>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text"> Comentarios </span>
            <textarea
              name="comments"
              id="comments"
              class="form-control"
              formControlName="comments"
              aria-label="Comentarios"
              [ngClass]="{
                'is-invalid': submit && fe.comments.errors,
                'is-valid': submit && !fe.comments.errors
              }"
            ></textarea>
          </div>

          <div class="row">
            <div class="d-grip gap-2 d-md-flex justify-content-md-end">
              <button
                type="submit"
                class="btn btn-outline-success"
                [disabled]="!disabledScheduleButton"
              >
                <!-- disableButton || disabledScheduleButton-->
                Agendar sesion
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="flagTypeSession == 3">
    <div class="modal-body">
      <form
        [formGroup]="formVacations"
        (ngSubmit)="scheduleVacations()"
        novalidate
      >
        <input type="hidden" name="officeId" formControlName="officeId" />
        <input type="hidden" name="cabinId" formControlName="cabinId" />
        <input type="hidden" name="employeeId" formControlName="employeeId" />
        <div class="row">
          <select2
            [data]="employeesFilter"
            displaySearchStatus="always"
            style="width: 100%"
            placeholder="Colaboradores"
            resettable
            listPosition="below"
            styleMode="material"
            noResultMessage="Colaborador no encontrado"
            (update)="updateClientsVacations($event)"
            [ngClass]="{
              'is-invalid': submittedVacationsForm && fvf.tempEmployee.errors,
              'is-valid': submittedVacationsForm && !fvf.tempEmployee.errors
            }"
          ></select2>
        </div>

        <div class="row">
          <div class="col-md-6 mb-3 form-floating">
            <div class="input-group">
              <input
                type="text"
                name="startDate"
                class="form-control"
                id="startDate"
                formControlName="startDate"
                placeholder="Fecha de inicio de vacaciones"
                ngbDatepicker
                #d="ngbDatepicker"
                required
                readonly
                style="height: 3.5rem"
                [ngClass]="{
                  'is-valid': submittedVacationsForm && !fvf.startDate.errors,
                  'is-invalid': submittedVacationsForm && fvf.startDate.errors
                }"
              />
              <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="d.toggle()"
                type="button"
              ></button>
              <div
                *ngIf="
                  fvf.startDate?.errors &&
                  fvf.startDate?.invalid &&
                  submittedVacationsForm
                "
              >
                <div class="text-danger" *ngIf="fvf.startDate.errors.required">
                  Por favor, seleccione una fecha de inicio de vacaciones.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3 form-floating">
            <div class="input-group">
              <input
                type="text"
                name="dateSession"
                class="form-control"
                id="endDate"
                formControlName="endDate"
                placeholder="Fecha de fin de vacaciones"
                ngbDatepicker
                #d2="ngbDatepicker"
                required
                readonly
                style="height: 3.5rem"
                [ngClass]="{
                  'is-valid': submittedVacationsForm && !fvf.endDate.errors,
                  'is-invalid': submittedVacationsForm && fvf.endDate.errors
                }"
              />
              <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="d2.toggle()"
                type="button"
              ></button>
              <div
                *ngIf="
                  fvf.endDate?.errors &&
                  fvf.endDate?.invalid &&
                  submittedVacationsForm
                "
              >
                <div class="text-danger" *ngIf="fe.dateSession.errors.required">
                  Por favor, seleccione una fecha de fin de vacaciones.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 offset-md-2">
            <label for="startSession">Hora de inicio</label>
            <ngb-timepicker
              [(ngModel)]="startVacations"
              [ngModelOptions]="{ standalone: true }"
              [minuteStep]="step"
            ></ngb-timepicker>
          </div>
          <div class="col-md-3 offset-md-2">
            <label for="endSession">Hora de fin</label>
            <ngb-timepicker
              [(ngModel)]="endVacations"
              [ngModelOptions]="{ standalone: true }"
              [minuteStep]="step"
            ></ngb-timepicker>
          </div>
        </div>

        <div class="row">
          <div class="d-grip gap-2 d-md-flex justify-content-md-end">
            <button
              type="submit"
              class="btn btn-outline-success"
              [disabled]="submittedVacationsForm"
            >
              Agendar vacaciones
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="flagTypeSession == 4">
    <div class="modal-body">
      <form
        [formGroup]="formCapacitation"
        (ngSubmit)="scheduleCapacitation()"
        novalidate
      >
        <input type="hidden" name="officeId" formControlName="officeId" />
        <input type="hidden" name="cabinId" formControlName="cabinId" />
        <input type="hidden" name="employeeId" formControlName="employeeId" />
        <div class="row">
          <div class="form-floating mb-3 col-md-6">
            <select
              name="tempEmployee"
              id="tempEmployee"
              class="form-control"
              formControlName="tempEmployee"
              required
              [ngClass]="{
                'is-valid': submit && !fc.tempEmployee.errors,
                'is-invalid': submit && fc.tempEmployee.errors
              }"
            >
              <option value="">Selecciona un colaborador</option>
              <option
                value="{{ employee.id }}"
                *ngFor="let employee of employees"
              >
                {{ employee.first_name }} {{ employee.last_name }} ({{
                  employee.email
                }})
              </option>
            </select>
            <label for="employeeId" class="ms-2">
              Selecciona un colaborador
            </label>
          </div>
          <div class="col-md-6 mb-3 form-floating">
            <div class="input-group">
              <input
                type="text"
                name="startDate"
                class="form-control"
                id="dateSession"
                formControlName="startDate"
                placeholder="Fecha de capacitación"
                ngbDatepicker
                #d="ngbDatepicker"
                required
                readonly
                style="height: 3.5rem"
                [ngClass]="{
                  'is-valid': submit && !fc.startDate.errors,
                  'is-invalid': submit && fc.startDate.errors
                }"
              />
              <button
                class="btn btn-outline-secondary bi bi-calendar3"
                (click)="d.toggle()"
                type="button"
              ></button>
              <div
                *ngIf="
                  fc.startDate?.errors &&
                  fc.startDate?.invalid &&
                  submittedCapacitationForm
                "
              >
                <div class="text-danger" *ngIf="fc.startDate.errors.required">
                  Por favor, seleccione una fecha de capacitaci&oacute;n.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 offset-md-2">
            <label for="startCapacitation">Hora de inicio</label>
            <ngb-timepicker
              [(ngModel)]="startCapacitation"
              [ngModelOptions]="{ standalone: true }"
            ></ngb-timepicker>
          </div>
          <div class="col-md-3 offset-md-2">
            <label for="endCapacitation">Hora de fin</label>
            <ngb-timepicker
              [(ngModel)]="endCapacitation"
              [ngModelOptions]="{ standalone: true }"
              [minuteStep]="step"
            ></ngb-timepicker>
            <div class="text-danger" *ngIf="submittedExist">
              Por favor, selecciona una hora de termino de sesion.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="d-grip gap-2 d-md-flex justify-content-md-end">
            <button type="submit" class="btn btn-outline-success">
              Agendar capacitaci&oacute;n
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #modalCloseBox let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Corte de Caja</h5>
    <button type="button" (click)="close()" class="close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card mt-2" *ngFor="let box of closeBox">
      <div class="card-body" *ngIf="box.pay.data.length == 0">
        <div class="alert alert-danger" role="alert">
          No hay ingresos con "{{ box.pay.method_name }}
        </div>
      </div>
      <div class="card-body" *ngIf="box.pay.data.length > 0">
        <h5 class="card-title">{{ box.pay.method_name }}</h5>
        <table class="table table-striped mt-2">
          <thead class="table-dark">
            <tr>
              <th>Resumen</th>
              <th>Pago</th>
              <th>Fecha de venta</th>
              <th>Areas</th>
              <th>Metodo de pago</th>
            </tr>
          </thead>
          <tbody *ngFor="let data of box.pay.data">
            <tr>
              <td>{{ data.title }}</td>
              <td>{{ data.pago | currency }}</td>
              <td>{{ data.venta }}</td>
              <td>{{ data.areas }}</td>
              <td>{{ data.method }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="row mt-1 justify-content-end fw-bold"
          style="margin-right: 5px !important"
        >
          Total : {{ box.pay.total | currency }}
        </div>
        <div
          class="d-grid gap-2 d-md-flex justify-content-md-end mt-2"
          *ngIf="box.pay.existsData == 0"
        >
          <button
            type="button"
            class="btn btn-outline-success"
            (click)="
              confirmData(
                1,
                box.pay.total,
                box.pay.id_method,
                box.pay.date_send
              )
            "
          >
            Confirmar
          </button>
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="
              confirmData(
                0,
                box.pay.total,
                box.pay.id_method,
                box.pay.date_send
              )
            "
          >
            Rechazar
          </button>
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-body" *ngIf="closeBoxChange.change_office.length == 0">
        <div class="alert alert-danger" role="alert">
          No hay clientes con cambios de sucursal.
        </div>
      </div>
      <div class="card-body" *ngIf="closeBoxChange.change_office.length > 0">
        <h5 class="card-title">Cambios de Sucursal</h5>
        <table class="table table-striped mt-2">
          <thead class="table-dark">
            <tr>
              <th>Cliente</th>
              <th>Pago</th>
              <th>Fecha de cambio</th>
              <th>Metodo de Pago</th>
            </tr>
          </thead>
          <tbody *ngFor="let data of closeBoxChange.change_office">
            <tr>
              <td>{{ data.cliente }}</td>
              <td>{{ data.pago | currency }}</td>
              <td>{{ data.venta }}</td>
              <td>{{ data.method }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="row mt-1 justify-content-end fw-bold"
          style="margin-right: 5px !important"
        >
          Total : {{ closeBoxChange.total | currency }}
        </div>
        <div
          class="d-grid gap-2 d-md-flex justify-content-md-end mt-2"
          *ngIf="closeBoxChange.paymentClose == 0"
        >
          <button
            type="button"
            class="btn btn-outline-success"
            (click)="
              confirmDataChange(
                1,
                closeBoxChange.total,
                closeBoxChange.date_send
              )
            "
          >
            Confirmar
          </button>
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="
              confirmDataChange(
                0,
                closeBoxChange.total,
                closeBoxChange.date_send
              )
            "
          >
            Rechazar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      Cerrar
    </button>
  </div>
</ng-template>

<ng-template #changeOffice let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Cambio de Sucursal</h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card mt-2">
      <div class="card-body">
        <form
          [formGroup]="changeForm"
          (ngSubmit)="reloadNewOffice()"
          novalidate
        >
          <div class="form-floating mb-3">
            <select
              name="office_id_change"
              id="office_id_change"
              class="form-control"
              formControlName="office_id_change"
              required
              [ngClass]="{
                'is-valid': submit && !cf.office_id_change.errors,
                'is-invalid': submit && cf.office_id_change.errors
              }"
            >
              <option selected value="">Selecciona una sucursal</option>
              <option value="{{ office.id }}" *ngFor="let office of offices">
                {{ office.name }}
              </option>
            </select>
            <label for="office_id_change" class="">
              Selecciona una sucursal
            </label>
          </div>
          <div class="row">
            <div class="d-grip gap-2 d-md-flex justify-content-md-end">
              <button type="submit" class="btn btn-outline-success">
                Cambiar sucursal
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmAssited let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">
      Confirmar Asistencia
      <button
        type="button"
        class="btn btn-outline-danger ms-2"
        (click)="deleteSession(displayInfoAppoinment.session_id)"
      >
        <span *ngIf="displayInfoAppoinment.typeSession == 1"
          >Eliminar Sesi&oacute;n</span
        >
        <span *ngIf="displayInfoAppoinment.typeSession == 2"
          >Eliminar Valoraci&oacute;n</span
        >
      </button>
    </h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card mt-2">
      <div class="card-body">
        <div
          class="mb-2 alert {{ typeColor }}"
          role="alert"
          *ngIf="disabledAcceptanceButton"
        >
          {{ messageAcceptance }}
        </div>
        <div class="row">
          <div class="ms-1 me-1 btn-group">
            <button
              type="button"
              class="btn btn-success"
              (click)="
                confirmAssitedToSession(1, displayInfoAppoinment.session_id)
              "
              [disabled]="disabledAcceptanceButton"
            >
              <i class="bi bi-check2-circle icon-color-button"></i>
              Confirmo asistencia
            </button>
            <!-- confirmAssitedToSession(2, displayInfoAppoinment[0].sessions.id) -->
            <button
              type="button"
              class="btn btn-danger ms-1"
              (click)="openNoAssisstForm(true, 1)"
              [disabled]="disabledAcceptanceButton"
            >
              <i class="bi bi-x-circle icon-color-button"></i>
              Confirmo no asistencia
            </button>
            <button
              class="btn btn-warning ms-1"
              (click)="showRescheduleForm(true)"
            >
              <i class="bi bi-calendar3"></i>
              Reagendar
            </button>
          </div>
        </div>
      </div>
      <div
        class="alert alert-success mt-2 ms-2 me-2"
        role="alert"
        *ngIf="submitReschedule && messageTextRescchedule != ''"
      >
        {{ messageTextRescchedule }}
      </div>

      <div class="card mb-1" *ngIf="noAssistFlag">
        <div class="card-body">
          <h5 class="card-title">
            Raz&oacute;n de <strong>No Asistencia</strong>
          </h5>
          <div class="card-title">
            <form
              [formGroup]="cancelForm"
              (ngSubmit)="confirmAssitedToSessionPost()"
              novalidate
            >
              <input type="hidden" name="id" formControlName="id" />
              <input type="hidden" name="type" formControlName="type" />
              <div class="row">
                <div class="mb-3 input-group">
                  <span class="input-group-text"> Comentarios </span>
                  <textarea
                    name="comments"
                    id="comments"
                    class="form-control"
                    formControlName="comments"
                    aria-label="Comentarios"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="d-grip gap-2 d-md-flex justify-content-md-end">
                  <button type="submit" class="btn btn-outline-success">
                    Confirmar no asistencia
                  </button>
                  <button
                    type="button"
                    (click)="openNoAssisstForm(false, 0)"
                    class="btn btn-outline-danger"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="card mb-1" *ngIf="reschedule">
        <div class="card-body">
          <h5 class="card-title">Reagendar Sesi&oacute;n</h5>
          <div class="card-title">
            <form
              [formGroup]="rescheduleForm"
              (ngSubmit)="rescheduleSession()"
              novalidate
            >
              <input type="hidden" name="sess_id" formControlName="sess_id" />
              <div class="row">
                <div class="mb-3 form-floating">
                  <div class="input-group">
                    <input
                      type="text"
                      name="new_day"
                      class="form-control"
                      id="new_day"
                      formControlName="new_day"
                      placeholder="Fecha de reagenda de sesion"
                      ngbDatepicker
                      #d="ngbDatepicker"
                      required
                      readonly
                      style="height: 3.5rem"
                      [ngClass]="{
                        'is-valid': submitReschedule && !reg.new_day.errors,
                        'is-invalid': submitReschedule && reg.new_day.errors
                      }"
                    />
                    <button
                      class="btn btn-outline-secondary bi bi-calendar3"
                      (click)="d.toggle()"
                      type="button"
                    ></button>
                    <div
                      *ngIf="
                        reg.new_day?.errors &&
                        reg.new_day?.invalid &&
                        submitReschedule
                      "
                    >
                      <div
                        class="text-danger"
                        *ngIf="reg.new_day.errors.required"
                      >
                        Por favor, seleccione una fecha para reagendar la
                        sesi&oacute;n.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-3 offset-md-2">
                  <label for="startSession">Hora de inicio</label>
                  <ngb-timepicker
                    [(ngModel)]="startSession"
                    [ngModelOptions]="{ standalone: true }"
                    [spinners]="true"
                  ></ngb-timepicker>
                </div>
                <div class="col-md-3 offset-md-2">
                  <label for="endSession">Hora de fin</label>
                  <ngb-timepicker
                    [(ngModel)]="endSession"
                    [ngModelOptions]="{ standalone: true }"
                    [minuteStep]="step"
                    [spinners]="true"
                  ></ngb-timepicker>
                  <div class="text-danger" *ngIf="submittedExist">
                    Por favor, selecciona una hora de termino de sesion.
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="d-grip gap-2 d-md-flex justify-content-md-end">
                  <button type="submit" class="btn btn-outline-success">
                    Aceptar reagenda
                  </button>
                  <button
                    type="button"
                    (click)="showRescheduleForm(false)"
                    class="btn btn-outline-danger"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sessionsPerClient let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Informacion de Sesiones de {{ clientName }}</h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="flagSearchModal">
    <div class="mb-2 alert alert-danger" role="alert">
      No hay sesiones programadas para el cliente
    </div>
    <div class="row">
      <div class="d-grip gap-2 d-md-flex justify-content-md-end">
        <button type="button" (click)="close()" class="btn btn-outline-danger">
          Cerrar
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!flagSearchModal">
    <div>
      <div class="card mt-2">
        <div class="ms-1 me-1">
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <th class="col"># de sesi&oacute;n</th>
                <th class="col">Inicio</th>
                <th class="col">Fin</th>
                <th class="col">Asistio?</th>
                <th class="col">Atendio</th>
                <th class="col">Areas de paquete</th>
                <th class="col">Total de sesiones</th>
                <th class="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody *ngFor="let session of perClients">
              <tr>
                <td>Sesi&oacute;n {{ session.number_session }}</td>
                <td>{{ session.session_start }}</td>
                <td>{{ session.session_end }}</td>
                <td>{{ session.assisted ? "Si" : "No" }}</td>
                <td>
                  {{
                    session.real_user.name.length == 0
                      ? session.user.name
                      : session.real_user.name
                  }}
                </td>
                <td>{{ session.areas_session }}</td>
                <td>{{ session.package.session_number }}</td>
                <td>
                  <button
                    class="btn btn-success"
                    (click)="calendarPerClientDate(session.session_id)"
                  >
                    Ir a la sesion
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #closeCapacitation let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Eliminar Capacitaci&oacute;n</h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card mt-2">
      <div class="card-body">
        <div class="row">
          <div class="ms-1 me-1 btn-group">
            <button
              type="button"
              class="btn btn-danger ms-1"
              (click)="
                deleteCapacitationSession(displayInfoAppoinment.session_id)
              "
            >
              <i class="bi bi-check-circle icon-color-button"></i>
              Confirmar eliminaci&oacute;n
            </button>
            <button class="btn btn-success ms-1" (click)="close()">
              <i class="bi bi-x-circle icon-color-button"></i>
              Cancelar eliminaci&oacute;n
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
