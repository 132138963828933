import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CalendarComponent } from './components/dashboard/calendar/calendar.component';

import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';

import { HomeLayoutComponent } from './components/layouts/home-layout/home-layout.component';
import { LoginLayoutComponent } from './components/layouts/login-layout/login-layout.component';

import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { ListPaymentMethodsComponent } from './components/payment-methods/list-payment-methods/list-payment-methods.component';
import { CreatePaymentMethodsComponent } from './components/payment-methods/create-payment-methods/create-payment-methods.component';
import { UpdatePaymentMethodsComponent } from './components/payment-methods/update-payment-methods/update-payment-methods.component';

import { PackagesComponent } from './components/packages/packages.component';
import { CreatePackagesComponent } from './components/packages/create-packages/create-packages.component';
import { ListPackagesComponent } from './components/packages/list-packages/list-packages.component';
import { UpdatePackagesComponent } from './components/packages/update-packages/update-packages.component';
import { ShowPackageComponent } from './components/packages/show-package/show-package.component';
import { AdvancePaymentsComponent } from './components/packages/advance-payments/advance-payments.component';

import { PaymentsComponent } from './components/payments/payments.component';
import { ListPaymentsComponent } from './components/payments/list-payments/list-payments.component';
import { CreatePaymentsComponent } from './components/payments/create-payments/create-payments.component';
import { UpdatePaymentsComponent } from './components/payments/update-payments/update-payments.component';

import { CitiesComponent } from './components/cities/cities.component';
import { ListCitiesComponent } from './components/cities/list-cities/list-cities.component';
import { CreateCitiesComponent } from './components/cities/create-cities/create-cities.component';
import { EditCitiesComponent } from './components/cities/edit-cities/edit-cities.component';

import { RolesComponent } from './components/roles/roles.component';
import { ListRolesComponent } from './components/roles/list-roles/list-roles.component';
import { CreateRolesComponent } from './components/roles/create-roles/create-roles.component';
import { UpdateRolesComponent } from './components/roles/update-roles/update-roles.component';

import { CyclesComponent } from './components/cycles/cycles.component';
import { UpdateCycleComponent } from './components/cycles/update-cycle/update-cycle.component';
import { CreateCycleComponent } from './components/cycles/create-cycle/create-cycle.component';
import { ListCycleComponent } from './components/cycles/list-cycle/list-cycle.component';

import { BonusComponent } from './components/bonus/bonus.component';
import { CreateBonusComponent } from './components/bonus/create-bonus/create-bonus.component';
import { ListBonusComponent } from './components/bonus/list-bonus/list-bonus.component';
import { UpdateBonusComponent } from './components/bonus/update-bonus/update-bonus.component';

import { OfficesComponent } from './components/offices/offices.component';
import { CreateOfficeComponent } from './components/offices/create-office/create-office.component';
import { ListOfficeComponent } from './components/offices/list-office/list-office.component';
import { UpdateOfficeComponent } from './components/offices/update-office/update-office.component';

import { EmployeesComponent } from './components/employees/employees.component';
import { ListEmployeesComponent } from './components/employees/list-employees/list-employees.component';
import { CreateEmployeeComponent } from './components/employees/create-employee/create-employee.component';
import { UpdateEmployeesComponent } from './components/employees/update-employees/update-employees.component';
import { UpdatePasswordComponent } from './components/employees/update-password/update-password.component';
import { SetPermissionsComponent } from './components/employees/set-permissions/set-permissions.component';

import { ClientsComponent } from './components/clients/clients.component';
import { CreateClientComponent } from './components/clients/create-client/create-client.component';
import { UpdateClientComponent } from './components/clients/update-client/update-client.component';
import { ListClientComponent } from './components/clients/list-client/list-client.component';
import { ShowClientComponent } from './components/clients/show-client/show-client.component';
import { ChangeOfficeComponent } from './components/clients/change-office/change-office.component';

import { ProspectsComponent } from './components/prospects/prospects.component';
import { CreateComponent as ProspectCreate } from './components/prospects/create/create.component';
import { ListsProspectsComponent } from './components/prospects/lists-prospects/lists-prospects.component';

import { SellsComponent } from './components/sells/sells.component';
import { DisplaySellsComponent } from './components/sells/display-sells/display-sells.component';
import { ListSellsComponent } from './components/sells/list-sells/list-sells.component';
import { NextDayDisplayComponent } from './components/sells/next-day-display/next-day-display.component';

import { BlackDebtsComponent } from './components/black-debts/black-debts.component';
import { ListBlackDebtsComponent } from './components/black-debts/list-black-debts/list-black-debts.component';
import { CreateBlackDebtsComponent } from './components/black-debts/create-black-debts/create-black-debts.component';
import { ShowBlackDebtsComponent } from './components/black-debts/show-black-debts/show-black-debts.component';

import { PromotionsComponent } from './components/promotions/promotions.component';
import { ListComponent as ListPromotionsComponent } from './components/promotions/list/list.component';
import { CreateComponent as CreatePromotionsComponent } from './components/promotions/create/create.component';
import { ShowPromotionComponent } from './components/promotions/show-promotion/show-promotion.component';

import { CabinsComponent } from './components/cabins/cabins.component';
import { ListCabinsComponent } from './components/cabins/list-cabins/list-cabins.component';
import { CreateCabinsComponent } from './components/cabins/create-cabins/create-cabins.component';
import { EditCabinsComponent } from './components/cabins/edit-cabins/edit-cabins.component';

import { WeeklySellsComponent } from './components/weekly-sells/weekly-sells.component';
import { ListWeeklySellsComponent } from './components/weekly-sells/list-weekly-sells/list-weekly-sells.component';
import { ShowWeeklySellsComponent } from './components/weekly-sells/show-weekly-sells/show-weekly-sells.component';

import { DailyPaymentsComponent } from './components/daily-payments/daily-payments.component';
import { ListDailyPaymentsComponent } from './components/daily-payments/list-daily-payments/list-daily-payments.component';
import { ShowDailyPaymentsComponent } from './components/daily-payments/show-daily-payments/show-daily-payments.component';
import { ShowNextdayPaymentsComponent } from './components/daily-payments/show-nextday-payments/show-nextday-payments.component';

import { ProductsComponent } from './components/products/products.component';
import { CreateProductComponent } from './components/products/create-product/create-product.component';
import { ListProductsComponent } from './components/products/list-products/list-products.component';
import { ShowProductComponent } from './components/products/show-product/show-product.component';

import { CommissionsComponent } from './components/commissions/commissions.component';
import { ListCommissionsComponent } from './components/commissions/list-commissions/list-commissions.component';
import { UpdateCommissionsComponent } from './components/commissions/update-commissions/update-commissions.component';
import { CreateCommissionsComponent } from './components/commissions/create-commissions/create-commissions.component';

import { PayrollsComponent } from './components/payrolls/payrolls.component';
import { ListPayrollComponent } from './components/payrolls/list-payroll/list-payroll.component';
import { ShowPayrollComponent } from './components/payrolls/show-payroll/show-payroll.component';
import { CreatePayrollComponent } from './components/payrolls/create-payroll/create-payroll.component';

import { VacationsComponent } from './components/vacations/vacations.component';
import { ListComponent as ListVacationsComponent } from './components/vacations/list/list.component';
import { CreateComponent as CreateVacationsComponent } from './components/vacations/create/create.component';
import { UpdateComponent as UpdateVacationsComponent } from './components/vacations/update/update.component';

import { SmallBoxComponent } from './components/small-box/small-box.component';
import { ListComponent as ListSmallBoxComponent } from './components/small-box/list/list.component';
import { CreateComponent as CreateSmallBoxComponent } from './components/small-box/create/create.component';

import { AreasComponent } from './components/areas/areas.component';
import { CreateAreasComponent } from './components/areas/create-areas/create-areas.component';
import { ListAreasComponent } from './components/areas/list-areas/list-areas.component';
import { UpdateAreasComponent } from './components/areas/update-areas/update-areas.component';
import { ShowComponent as ShowAreaComponent } from './components/areas/show/show.component';

const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
    ],
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'redirect-calendar',
        redirectTo: 'dashboard/calendar',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'calendar',
            component: CalendarComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'payments-methods',
        component: PaymentMethodsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListPaymentMethodsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreatePaymentMethodsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdatePaymentMethodsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'packages',
        component: PackagesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListPackagesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreatePackagesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdatePackagesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'information/:id',
            component: ShowPackageComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'advance-payments/:id',
            component: AdvancePaymentsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListPaymentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreatePaymentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdatePaymentsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'cities',
        component: CitiesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListCitiesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateCitiesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: EditCitiesComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListRolesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateRolesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateRolesComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'cycles',
        component: CyclesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListCycleComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateCycleComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateCycleComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'bonus',
        component: BonusComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'create',
            component: CreateBonusComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'list',
            component: ListBonusComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateBonusComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'offices',
        component: OfficesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'create',
            component: CreateOfficeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'list',
            component: ListOfficeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateOfficeComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'employees',
        component: EmployeesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'create',
            component: CreateEmployeeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'list',
            component: ListEmployeesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateEmployeesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'update-password/:id',
            component: UpdatePasswordComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'employee-permissions/:id',
            component: SetPermissionsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'create',
            component: CreateClientComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'list',
            component: ListClientComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateClientComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show/:id',
            component: ShowClientComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'change/:id',
            component: ChangeOfficeComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'prospects',
        component: ProspectsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'create',
            component: ProspectCreate,
            canActivate: [AuthGuard],
          },
          {
            path: 'list',
            component: ListsProspectsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'daily-sells',
        component: SellsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListSellsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'display/:date/:office_id',
            component: DisplaySellsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'display-next/:date/:office_id',
            component: NextDayDisplayComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'black-debts',
        component: BlackDebtsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListBlackDebtsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'info/:id/:apptId',
            component: ShowBlackDebtsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'promotions',
        component: PromotionsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListPromotionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreatePromotionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show/:id',
            component: ShowPromotionComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'cabins',
        component: CabinsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListCabinsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateCabinsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: EditCabinsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'weekly-sells',
        component: WeeklySellsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListWeeklySellsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show/:id/:start/:end/:type',
            component: ShowWeeklySellsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'daily-payments',
        component: DailyPaymentsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListDailyPaymentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show-daily/:id',
            component: ShowDailyPaymentsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show-next-day/:id',
            component: ShowNextdayPaymentsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'products',
        component: ProductsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListProductsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateProductComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show/:id',
            component: ShowProductComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'commissions',
        component: CommissionsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListCommissionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateCommissionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateCommissionsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'payrolls',
        component: PayrollsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListPayrollComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreatePayrollComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show/:id',
            component: ShowPayrollComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'vacations',
        component: VacationsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListVacationsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateVacationsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit/:id',
            component: UpdateVacationsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'small-box',
        component: SmallBoxComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListSmallBoxComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create/:officeId/:filterDate',
            component: CreateSmallBoxComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'areas',
        component: AreasComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'list',
            component: ListAreasComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create',
            component: CreateAreasComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'update/:areaId/:officeId',
            component: UpdateAreasComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'show/:areaId/:officeId',
            component: ShowAreaComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
