<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Nomina</h3>
  </div>
  <hr />
  <div class="d-grip gap-1 d-md-flex justify-content-md-end mb-3">
    <a [routerLink]="['/payrolls/list']" class="btn btn-outline-success">
      <i class="bi bi-arrow-left-circle"></i>
      Regresar a lista de nominas
    </a>
  </div>
  <hr />
</div>

<div class="row">
  <div class="col-md-6">
    <h4>
      Salario Base Quincenal:
      <strong>{{ payrollData.payroll.salary | currency }}</strong>
    </h4>
  </div>
  <div class="col-md-6">
    <h4>
      Total Nomina con Comisiones:
      <strong>{{
        payrollData.payroll.total_paid * 1 +
          payrollData.payroll.extra_data_bonus.prizes * 1 | currency
      }}</strong>
    </h4>
  </div>
</div>

<div class="row mt-2">
  <div class="card">
    <h5 class="card-title mt-2 text-center">Datos del Colaborador</h5>
    <div class="row mt-1">
      <div class="col-md-6">
        <p>
          Nombre: <strong>{{ payrollData.user.name }}</strong>
        </p>
        <p>
          Puesto: <strong>{{ payrollData.role.role }}</strong>
        </p>
      </div>
      <div class="col-md-6">
        <p>
          Fechas de pago:
          <strong
            >{{ payrollData.cycle.date_start }} al
            {{ payrollData.cycle.date_end }}</strong
          >
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row mt-2 mb-5">
  <div class="card">
    <div class="card-title text-center"><h5>Percepciones</h5></div>
    <div class="row">
      <table class="table mb-2 ms-2 me-2">
        <thead>
          <tr>
            <th>Concepto</th>
            <th>Total</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Premios</td>
            <td>-</td>
            <td>
              {{ payrollData.payroll.extra_data_bonus.prizes | currency }}
            </td>
          </tr>
          <tr>
            <td>Reventas</td>
            <td>
              {{ payrollData.payroll.extra_data_bonus.bonus_total_resell }}
            </td>
            <td>
              {{
                payrollData.payroll.extra_data_bonus.bonus_resell_amount
                  | currency
              }}
            </td>
          </tr>
          <tr>
            <td>Areas Vendidas</td>
            <td>
              {{ payrollData.payroll.extra_data_bonus.bonus_total_areas }}
            </td>
            <td>
              {{
                payrollData.payroll.extra_data_bonus.bonus_areas_amount
                  | currency
              }}
            </td>
          </tr>
          <tr>
            <td>Bono Lunes a Viernes</td>
            <td>-</td>
            <td>{{ payrollData.payroll.bonus_lv | currency }}</td>
          </tr>
          <tr>
            <td>Bono de Lunes a Domingo</td>
            <td>-</td>
            <td>{{ payrollData.payroll.bonus_ld | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-md-4 offset-md-8">
        <strong>
          Importe Neto:
          {{
            payrollData.payroll.extra_data_bonus.prizes * 1 +
              payrollData.payroll.extra_data_bonus.bonus_resell_amount * 1 +
              payrollData.payroll.extra_data_bonus.bonus_areas_amount * 1 +
              payrollData.payroll.bonus_lv * 1 +
              payrollData.payroll.bonus_ld * 1 | currency
          }}
        </strong>
      </div>
    </div>
  </div>
</div>
