<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de Nominas</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-12">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a (click)="openCreateReport()" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Generar Nomina
        </a>
      </div>
    </div>
  </div>
  <hr />

  <ngb-accordion [closeOthers]="true" activeIds="static-0" class="mt-3">
    <ngb-panel
      *ngFor="let report of payrolls; let i = index"
      id="static-{{ i }}"
      title="Nomina del {{ report.cycle_date_start }} al {{
        report.cycle_date_end
      }}"
    >
      <ng-template ngbPanelContent>
        <div class="accordion-body">
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <td>Colaborador</td>
                <td>Email</td>
                <td>Telefono</td>
                <td>Total</td>
                <td>Opciones</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let employee of report.employees">
                <td>{{ employee.user.name }}</td>
                <td>{{ employee.user.email }}</td>
                <td>{{ employee.user.phone }}</td>
                <td>{{ employee.total_paid | currency }}</td>
                <td>
                  <div class="me-2">
                    <a
                      [routerLink]="['/payrolls/show', employee.user.payrollId]"
                      placement="top"
                      ngbTooltip="Motrar Nomina"
                      role="button"
                      class="btn btn-primary"
                    >
                      <i class="bi bi-eye-fill"></i>
                    </a>
                    <a
                      (click)="downloadPDF(report.payroll_id)"
                      class="btn btn-primary ms-2"
                      placement="top"
                      ngbTooltip="Descargar Nomina PDF"
                      role="button"
                    >
                      <i class="bi bi-file-earmark-arrow-down-fill"></i>
                    </a>
                    <a
                      (click)="downloadExcel(report.payroll_id)"
                      placement="top"
                      ngbTooltip="Descargar Nomina Excel"
                      role="button"
                      class="btn btn-primary ms-2"
                    >
                      <i class="bi bi-file-earmark-excel-fill"></i>
                    </a>
                    <a
                      (click)="
                        openGifts(employee.user.name, employee.user.payrollId)
                      "
                      placement="top"
                      ngbTooltip="Agregar Premios"
                      role="button"
                      class="btn btn-primary ms-2"
                    >
                      <i class="bi bi-gift-fill"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <ng-template #addPrizes let-close="close">
    <div class="modal-header">
      <h4 class="modal-title">Premios para {{ userName }}</h4>
      <button class="close" type="button" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Agrega Premios</p>
      <div class="row ms-2 me-2">
        <ngb-alert
          #selfClosingAlert
          type="danger"
          *ngIf="message != '' && flagErrors"
        >
          {{ message }}
        </ngb-alert>
        <ngb-alert
          #selfClosingAlert
          type="success"
          *ngIf="message != '' && isSaved"
        >
          {{ message }}
        </ngb-alert>
        <form [formGroup]="prizesForm" (ngSubmit)="addPrizesForm()" novalidate>
          <input
            type="hidden"
            name="payrollId"
            id="payrollId"
            class="form-control"
            formControlName="payrollId"
          />
          <div class="row">
            <div class="input-group mb-3">
              <span class="input-group-text">$</span>
              <input
                type="text"
                class="form-control"
                id="amount"
                name="amount"
                formControlName="amount"
                placeholder="Cantidad del premio"
                required
                [ngClass]="{
                  'is-invalid': submitted && f.amount.errors,
                  'is-valid': submitted && !f.amount.errors
                }"
              />
            </div>
          </div>
          <div class="d-grip gap-2 d-md-flex justify-content-md-end">
            <button
              class="btn btn-outline-danger"
              type="button"
              (click)="close()"
            >
              Cerrar
            </button>
            <button type="submit" class="btn btn-outline-success">
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #createReportModal let-close="close">
    <div class="modal-header">
      <h4 class="modal-title">Generaci&oacute;n de Nominas</h4>
      <button class="close" type="button" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row ms-2 me-2">
        <ngb-alert
          #selfClosingAlert
          type="danger"
          *ngIf="message != '' && flagErrors"
        >
          {{ message }}
        </ngb-alert>
        <ngb-alert
          #selfClosingAlert
          type="success"
          *ngIf="message != '' && isSaved"
        >
          {{ message }}
        </ngb-alert>
        <form
          [formGroup]="generatePayroll"
          (ngSubmit)="generatePayrollForm()"
          novalidate
        >
          <div class="row">
            <div class="form-floating mb-3 col-md-12">
              <select
                name="cycleId"
                id="cycleId"
                class="form-select"
                aria-label="floating label select"
                formControlName="cycleId"
                [ngClass]="{
                  'is-invalid': submitted && fg.cycleId.errors,
                  'is-valid': submitted && !fg.cycleId.errors
                }"
              >
                <option value="" selected>Selecciona ciclo de pago</option>
                <option value="{{ cycle.id }}" *ngFor="let cycle of cycles">
                  {{ cycle.cycle }}
                </option>
              </select>
              <label for="cycleId" class="ms-2"> Selecciona una oficina </label>
            </div>
          </div>
          <div class="d-grip gap-2 d-md-flex justify-content-md-end">
            <button
              class="btn btn-outline-danger"
              type="button"
              (click)="close()"
            >
              Cancelar
            </button>
            <button type="submit" class="btn btn-outline-success">
              Generar
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
