import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';
import { isThisISOWeek } from 'date-fns';

@Component({
  selector: 'app-list-payroll',
  templateUrl: './list-payroll.component.html',
  styleUrls: ['./list-payroll.component.scss'],
})
export class ListPayrollComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  @ViewChild('createReportModal', { static: false })
  createReportModal!: TemplateRef<any>;
  @ViewChild('addPrizes', { static: false }) addPrizes!: TemplateRef<any>;
  prizesForm: any = FormGroup;
  generatePayroll: any = FormGroup;
  modalClose: any;
  breadcrumbs: any = [];
  payrolls: any = [];
  cycles: any = [];
  submitted: boolean = false;
  flagErrors: boolean = false;
  isSaved: boolean = false;
  message: string = '';
  userName: string = '';
  private _error = new Subject<string>();

  constructor(
    private api: ApiService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private gp: FormBuilder
  ) {}

  ngOnInit(): void {
    this._error.subscribe(
      (messageData: string) => (this.message = messageData)
    );
    this._error.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.flagErrors = false;
        this.isSaved = false;
        this.message = '';
      }
    });

    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de Nominas',
        link: false,
      },
    ];

    this.validation();
    this.validationGenerate();
    this.loadPayrolls();
    this.getCycles();
  }

  validation(): void {
    this.prizesForm = this.fb.group({
      payrollId: ['', [Validators.required]],
      amount: ['', [Validators.required]],
    });
  }

  validationGenerate(): void {
    this.generatePayroll = this.gp.group({
      cycleId: ['', [Validators.required]],
    });
  }

  loadPayrolls() {
    this.api.get('payroll').subscribe({
      next: (payroll) => {
        this.payrolls = payroll.data;
      },
      error: (err) => {},
    });
  }

  getCycles(): void {
    this.api.get('cycles').subscribe({
      next: (cycles) => {
        this.cycles = cycles.cycles;
      },
      error: (err) => {},
    });
  }

  openCreateReport(): void {
    this.modalClose = this.modal.open(this.createReportModal, {
      size: 'md',
    });
  }

  openGifts(userName: string, payrollId: Number): void {
    this.resetPrizes();
    this.userName = userName;
    this.prizesForm.patchValue({
      payrollId: payrollId,
    });

    this.modalClose = this.modal.open(this.addPrizes, {
      size: 'md',
    });
  }

  get f() {
    return this.prizesForm.controls;
  }

  get fg() {
    return this.generatePayroll.controls;
  }

  generatePayrollForm(): void {
    this.submitted = true;

    if (this.generatePayroll.invalid) {
      this.submitted = false;
      this.flagErrors = true;
      this.message = '';

      return;
    }

    const obj = {
      cycle: this.generatePayroll.value.cycleId,
    };
    this.api.create('payroll', obj).subscribe({
      next: (data) => {
        this.submitted = false;
        this.isSaved = true;
        this.message = data.message;
        this._error.next(this.message);
        this.loadPayrolls();
        this.resetPrizes();
      },
      error: (err) => {},
    });
  }

  addPrizesForm() {
    this.submitted = true;
    this.flagErrors = false;

    if (this.prizesForm.invalid) {
      this.flagErrors = true;
      this.submitted = false;
      this.message = 'Por favor, ingrese la cantidad del premio.';
      this._error.next(this.message);

      return;
    }

    if (isNaN(this.prizesForm.value.amount)) {
      this.flagErrors = true;
      this.submitted = false;
      this.message = 'Por favor, ingrese una cantidad numerica.';
      return;
    }

    const obj = {
      payrollId: this.prizesForm.value.payrollId,
      amount: this.prizesForm.value.amount,
    };

    this.api.create('payroll/prizes', obj).subscribe({
      next: (data) => {
        this.submitted = false;
        this.isSaved = true;
        this.message = data.message;
        this.prizesForm.patchValue({
          amount: '',
        });
        this._error.next(this.message);
        this.resetPrizes();
      },
      error: (err) => {},
    });
  }

  downloadPDF(payrollId: number): void {
    this.api.getById('payroll/pdf', payrollId).subscribe({
      next: (payroll) => {
        window.open(payroll.path, '_blank');
      },
      error: (err) => {},
    });
  }

  downloadExcel(payrollId: number): void {
    this.api.getById('payroll/excel', payrollId).subscribe({
      next: (payroll) => {
        window.open(payroll.path, '_blank');
      },
      error: (err) => {},
    });
  }

  close(): void {
    this.modalClose.close();
  }

  resetPrizes(): void {
    this.prizesForm.reset();
    this.generatePayroll.get('cycleId').setValue('');

    setTimeout(() => {
      this.submitted = false;
      this.flagErrors = false;
      this.isSaved = false;
      this.message = '';
      this.modalClose.close();
    }, 8000);
  }
}
