<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="col-md-12 mx-auto">
    <h3>Lista de clientes</h3>
  </div>
  <hr />
  <div class="row align-items-center mb-3">
    <div class="col-md-6">
      <div class="input-group">
        <input
          type="text"
          id="table-complete-search"
          name="searchText"
          class="form-control"
          placeholder="Buscar clientes"
          [(ngModel)]="filterText"
          (keyup)="filterRecords()"
        />

        <select
          class="form-select ms-1"
          name="currentOfficeId"
          [(ngModel)]="currentOfficeId"
          id="currentOfficeId"
          (change)="setCurrentOffice($event)"
          *ngIf="rolekey == 'admin'"
        >
          <option value="0" selected="selected">Selecciona sucursal</option>
          <option
            *ngFor="let office of offices"
            value="{{ office.id }}"
            selected="{ office.id == roleofficeid ? 'selected' : '' }"
          >
            {{ office.name }}
          </option>
        </select>

        <button
          class="btn btn-outline-danger ms-1"
          type="button"
          (click)="clearSearch()"
        >
          Limpiar busqueda
        </button>
      </div>
    </div>

    <div class="col-md-6">
      <div class="d-grip gap-1 d-md-flex justify-content-md-end">
        <a [routerLink]="['/clients/create']" class="btn btn-outline-success">
          <i class="bi bi-plus-circle"></i>
          Crear cliente
        </a>
      </div>
    </div>
  </div>
  <hr />

  <div
    class="alert alert-success"
    role="alert"
    *ngIf="flagDeleted || flagDeletedP"
  >
    {{ message }}
  </div>

  <form>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="col text-center">ID</th>
          <th class="col text-center">Nombre(s)</th>
          <th class="col text-center">Apellido(s)</th>
          <th class="col text-center">Email</th>
          <th class="col text-center">Tel&eacute;fono</th>
          <th class="col text-center">C&oacute;digo postal</th>
          <th class="col text-center">Sucursal</th>
          <th class="col text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let client of filterClients
              | paginate : { itemsPerPage: 15, currentPage: page }
          "
        >
          <td scope="row">
            {{ client.identifier | uppercase }}
          </td>
          <td>
            {{ client.first_name }}
          </td>
          <td>
            {{ client.last_name }}
          </td>
          <td>
            {{ client.email }}
          </td>
          <td>
            {{ client.phone }}
          </td>
          <td>
            {{ client.cp }}
          </td>
          <td>
            {{ client.office.name }}
          </td>
          <td>
            <div class="d-flex d-flex-row">
              <div>
                <a
                  [routerLink]="['/clients/show', client.id]"
                  placement="top"
                  ngbTooltip="Expediente"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-archive-fill"></i>
                </a>
              </div>
              <div class="ms-1" *ngIf="rolekey == 'admin'">
                <a
                  [routerLink]="['/clients/edit', client.id]"
                  placement="top"
                  ngbTooltip="Editar"
                  role="button"
                  class="btn btn-primary ms-1"
                >
                  <i class="bi bi-pencil-square"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  *ngIf="rolekey == 'admin'"
                  (click)="
                    openDeleteClient(
                      client.id,
                      client.first_name,
                      client.last_name
                    )
                  "
                  placement="top"
                  ngbTooltip="Eliminar"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-trash3-fill"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  *ngIf="rolekey == 'admin'"
                  (click)="
                    openListPackages(
                      client.id,
                      client.first_name,
                      client.last_name
                    )
                  "
                  placement="top"
                  ngbTooltip="Eliminar paquete"
                  role="button"
                  class="btn btn-primary"
                >
                  <i class="bi bi-folder-minus"></i>
                </a>
              </div>
              <div class="ms-2">
                <a
                  [routerLink]="['/clients/change', client.id]"
                  class="btn btn-primary"
                  placement="top"
                  ngbTooltip="Cambiar de sucursal"
                  role="button"
                >
                  <i class="bi bi-arrow-left-right"></i>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <pagination-controls
        (pageChange)="page = $event"
        nextLabel="Siguiente"
        previousLabel="Anterior"
        class="mt-3"
      ></pagination-controls>
    </div>
  </form>

  <ng-template #modalClient let-close="close">
    <div class="modal-header">
      <h4 class="modal-title">Eliminar cliente</h4>
      <button class="close" type="button" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Deseas eliminar al cliente <strong>{{ clientNameDeleted }}</strong>
      </p>
      <div class="row">
        <div class="d-grip gap-2 d-md-flex justify-content-md-end">
          <button
            type="button"
            (click)="deleteClients(idClient)"
            class="btn btn-outline-success"
          >
            Confirmar
          </button>
          <button
            class="btn btn-outline-danger"
            type="button"
            (click)="close()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalClientPackages let-close="close">
    <div class="modal-header">
      <h4 class="modal-title">
        Lista de paquetes de <strong>{{ clientNameDeleted }}</strong>
      </h4>
      <button class="close" type="button" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mt-2" *ngIf="filterPackages[0].package.length == 0">
        <div class="alert alert-danger" role="alert">
          No hay paquetes a eliminar!
        </div>
      </div>
      <div class="row" *ngIf="filterPackages[0].package.length != 0">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="col">ID</th>
              <th class="col">Areas</th>
              <th class="col">Precio</th>
              <th class="col">Duracion</th>
              <th class="col">Fecha de compra</th>
              <th class="col"># de sesiones</th>
              <th class="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let package of filterPackages[0].package">
              <td>{{ package.id }}</td>
              <td>{{ package.areas }}</td>
              <td>{{ package.price | currency }}</td>
              <td>{{ package.duration }}</td>
              <td>{{ package.datePurchased }}</td>
              <td>{{ package.sessionNumber }}</td>
              <td>
                <a
                  (click)="openDeletePackages(package.id)"
                  placement="top"
                  ngbTooltip="Eliminar"
                  role="button"
                  [ngClass]="{ 'link-disabled': package.finished }"
                  class="btn btn-primary"
                >
                  <i class="bi bi-trash3-fill"></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="flagOpenDeletePackage">
              <td colspan="5">Deseas eliminar el paquete seleccionado?</td>
              <td colspan="3">
                <button
                  class="btn btn-outline-danger"
                  (click)="cancelDeletePackage()"
                >
                  Cancelar
                </button>
                <button
                  class="ms-2 btn btn-outline-success"
                  (click)="deletePackages(packageId)"
                >
                  Confirmar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>

  <ng-template #modalChangeUser let-close="close"></ng-template>
</div>
