import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-change-office',
  templateUrl: './change-office.component.html',
  styleUrls: ['./change-office.component.scss'],
})
export class ChangeOfficeComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  changeForm: any = FormGroup;
  breadcrumbs: any = [];
  paramId: string | null = '';
  clientData: any = [];
  payment_methods: any = [];
  payments: any = [];
  offices: any = [];
  generalMessage: string = '';
  isDisplayed: boolean = false;
  isSaved: boolean = false;
  flagErrors: boolean = false;
  submitted: boolean = false;
  paymentsFilter: any = [];
  newOffice: string = '';
  private _message = new Subject<string>();

  constructor(
    private api: ApiService,
    private router: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.paramId = this.router.snapshot.paramMap.get('id');
    this._message.subscribe(
      (messageData: string) => (this.generalMessage = messageData)
    );
    this._message.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
        this.isDisplayed = false;
        this.isSaved = false;
        this.generalMessage = '';
        this.submitted = false;
      }
    });
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de clientes',
        path: '/clients/list',
        link: true,
      },
      {
        name: 'Cambio de Sucursal',
        link: false,
      },
    ];

    this.validations();
    this.getPaymentsMethods();
    this.getPayments();
    this.getClientData();
  }

  validations() {
    this.changeForm = this.fb.group({
      clientId: ['', [Validators.required]],
      clientName: [''],
      officeName: [''],
      amount: ['500.00', [Validators.required]],
      newOffice: ['', [Validators.required]],
      paymentMethodId: ['', [Validators.required]],
      paymentId: ['0', [Validators.required]],
      terminal: ['0', [Validators.required]],
    });
  }

  getPaymentsMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.payment_methods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  getPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
      },
      error: (err) => {},
    });
  }

  getOffices(clientOffice: Number) {
    console.log(clientOffice);
    this.api.get('offices').subscribe({
      next: (offices) => {
        const office = offices.offices;
        this.offices = office.filter((data: any) => data.id != clientOffice);
      },
      error: (err) => {},
    });
  }

  getClientData() {
    this.api.getById('clients/single', Number(this.paramId)).subscribe({
      next: (client) => {
        this.clientData = client.client;

        this.changeForm.patchValue({
          clientId: this.clientData.id,
          clientName: `${this.clientData.first_name} ${this.clientData.last_name}`,
          officeName: this.clientData.offices.name,
        });

        this.getOffices(this.clientData.offices.id);
      },
      error: (err) => {},
    });
  }

  get f() {
    return this.changeForm.controls;
  }

  checkPaymentMethods(evt: any): void {
    this.paymentsFilter = [];
    this.paymentsFilter = this.payments
      .sort((data1: any, data2: any) => data1.order - data2.order)
      .filter(
        (data: any) => data.payment_method_id === Number(evt.target.value)
      );
  }

  resetChangeOffice() {
    this.changeForm.get('paymentMethodId').reset('');
    this.changeForm.get('paymentId').reset('0');
    this.changeForm.get('terminal').reset('0');
    this.changeForm.get('newOffice').reset('');

    this.changeForm.patchValue({
      officeName: this.newOffice,
    });
    this.paymentsFilter = [];
  }

  updateOffice() {
    this.isDisplayed = false;
    this.submitted = true;

    if (this.changeForm.invalid) {
      this.generalMessage = 'Por favor, revisa los datos ingresados.';
      this.submitted = false;
      this.isDisplayed = true;

      return;
    }

    if (
      this.paymentsFilter.length > 0 &&
      (this.changeForm.value.terminal == '0' ||
        this.changeForm.value.paymentId == '0')
    ) {
      this.generalMessage = 'Por favor, verifique los datos ingresados.';
      this.submitted = false;
      this.isDisplayed = true;

      return;
    }

    const obj = {
      paymentMethodId: this.changeForm.value.paymentMethodId,
      paymentId: this.changeForm.value.paymentId,
      terminal: this.changeForm.value.terminal,
      clientId: this.changeForm.value.clientId,
      newOffice: this.changeForm.value.newOffice,
      amount: this.changeForm.value.amount,
    };

    this.api.create('client/change', obj).subscribe({
      next: (client) => {
        this.submitted = false;
        this.isSaved = true;
        this.generalMessage = client.message;
        this.newOffice = client.data.office;

        this._message.next(client.message);

        this.resetChangeOffice();
      },
      error: (err) => {},
    });
  }
}
